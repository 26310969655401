import { useEffect, useState } from 'react';
import {
  ArticleIcon,
  Button,
  Card,
  CardBody,
  Select,
  SelectOption,
  Typography,
} from '@la/ds-ui-components';
import { Player, createPlayer, editPlayer } from 'lib/apis/createPlayer';
import {
  formatFormFieldsForWorkflow,
  updateWorkflowRegistration,
} from 'lib/apis/updateWorkflowRegistration';
import { useRegistration } from 'lib/context/RegistrationContext/RegistrationContext';
import { formatFormFieldValue } from 'lib/utils/formField';
import { getLAHostnameParts } from 'lib/utils/urlUtils';
import { GroupAccountUser } from 'redux/services/groupAccountApi';
import { useAppSelector } from 'redux/store';
import { getUserName } from 'redux/userSlice';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import {
  CustomFieldsForm,
  CustomFieldsFormFields,
} from './CustomFieldsForm/CustomFieldsForm';
import {
  PlayerDetailsFormFields,
  PlayerDetailsModal,
} from './PlayerDetailsModal';
import { PlayerDetailsViewModal } from './PlayerDetailsViewModal';
import * as S from './RegistrationInfoCard.styles';

export const PLAYER_CREATION_SUCCESS_MESSAGE =
  'Your player has been created successfully.';

export const MEMBER_FORM_FIELDS_NAME = 'memberFormFields';

type RegistrationInfoCardProps = {
  allowEditMode?: boolean;
};

const mapPlayersToSelectOption = (
  players: GroupAccountUser[]
): SelectOption[] => {
  return players.map((player) => ({
    label: `${player.firstName} ${player.lastName}`,
    value: player.id.toString(),
  }));
};

const createNewPlayerOption = {
  label: '+ Create new player',
  value: 'create-new-player',
};

export const RegistrationInfoCard = ({
  allowEditMode = false,
}: RegistrationInfoCardProps) => {
  const { subdomain } = getLAHostnameParts();

  const [editMode, setEditMode] = useState(!allowEditMode);

  const [editPlayerModalOpen, setEditPlayerModalOpen] = useState(false);
  const [createPlayerModalOpen, setCreatePlayerModalOpen] = useState(false);
  const [playerDetailsModalLoading, setPlayerDetailsModalLoading] =
    useState(false);
  const [playerDetailsModalError, setPlayerDetailsModalError] =
    useState<string>();
  const [viewPlayerModalOpen, setViewPlayerModalOpen] = useState(false);

  const [createPlayerSnackbarOpen, setCreatePlayerSnackbardOpen] =
    useState(false);

  const {
    childPlayers: players,
    currentStep,
    existingRegistrationError,
    formFields,
    formSteps,
    loggedInUserId,
    memberFormFields,
    nonFileUploadFormFields,
    onNextClick,
    selectedPlayer,
    decodedData,
    dispatch,
    touched,
    updateFormFields,
    setError,
    updateWithExistingRegistration,
  } = useRegistration();

  if (!formFields || !decodedData) {
    throw new Error();
  }

  useEffect(() => {
    setError(!selectedPlayer && touched);
  }, [setError, selectedPlayer, touched]);

  const playerOptions = [
    ...mapPlayersToSelectOption(players),
    createNewPlayerOption,
  ];

  const selectedPlayerDetails = players.find(
    (player) => player.id.toString() === selectedPlayer
  );

  const handleEditIconClick = () => {
    setEditMode(true);
  };

  const handleSaveEdit = () => {
    setEditMode(false);
  };

  const handlePlayerSelection = (value: string) => {
    if (value === 'create-new-player') {
      setCreatePlayerModalOpen(true);
      return;
    }

    if (value) {
      updateWithExistingRegistration(value).then(() => {
        dispatch({ type: 'SET_SELECTED_PLAYER', payload: value });
      });
    }
  };

  const handleModalClose = (): void => {
    setTimeout(() => {
      document.body.style.removeProperty('pointer-events');
    }, 0);
  };

  const closePlayerDetailsModal = (): void => {
    setCreatePlayerModalOpen(false);
    setEditPlayerModalOpen(false);
    setPlayerDetailsModalLoading(false);
    handleModalClose();
  };

  const handlePlayerDetailsSubmit = (
    playerDetails: PlayerDetailsFormFields & CustomFieldsFormFields
  ): void => {
    setPlayerDetailsModalLoading(true);
    setPlayerDetailsModalError(undefined);

    // Non-member profile form fields
    const player: Player = {
      user: {
        firstName: playerDetails.firstName,
        lastName: playerDetails.lastName,
        gender: playerDetails.gender || undefined,
        birthdate: playerDetails.birthdate || undefined,
      },
      address: {
        address1: playerDetails.address1,
        city: playerDetails.city,
        state: playerDetails.state,
        zipCode: playerDetails.zipCode,
      },
    };

    const createOrEditPlayer = editPlayerModalOpen
      ? editPlayer({ siteDomain: subdomain, player })
      : createPlayer({ siteDomain: subdomain, player });

    createOrEditPlayer
      .then((response) => {
        dispatch({
          type: 'SET_GROUP_ACCOUNT_AND_SELECTED_PLAYER',
          payload: response,
        });

        const { members } = response;
        const newPlayerId = members[members.length - 1]?.user.id;
        if (newPlayerId) {
          updateFormFields({
            customFields: playerDetails[MEMBER_FORM_FIELDS_NAME],
            userId: newPlayerId,
          })
            .then(() => {
              closePlayerDetailsModal();
              setCreatePlayerSnackbardOpen(true);
            })
            .catch(() => {
              setPlayerDetailsModalLoading(false);

              const errorMessageAction = editPlayerModalOpen
                ? 'saved'
                : 'created';
              const errorMessage =
                `Your player was successfully ${errorMessageAction}, but ` +
                `there was an error saving one or more member profile fields. ` +
                `Please visit your player's member profile to edit their details.`;
              setPlayerDetailsModalError(errorMessage);
            });
        } else {
          closePlayerDetailsModal();
        }
      })
      .catch(() => {
        setPlayerDetailsModalLoading(false);

        const errorMessageAction = editPlayerModalOpen ? 'saving' : 'creating';
        const errorMessage =
          `There was an error ${errorMessageAction} your player. ` +
          `Please try again in a few seconds.`;
        setPlayerDetailsModalError(errorMessage);
      });
  };

  const handleFormFieldSubmit = ({
    [formSteps[currentStep]]: customFields,
  }: CustomFieldsFormFields): void => {
    customFields.forEach((field) => {
      if (field.type !== 'FILE_UPLOAD') {
        dispatch({
          type: 'SET_NON_FILE_UPLOAD_FORM_FIELD',
          payload: {
            id: field.propertyDefinitionId,
            field,
          },
        });
      }
    });

    const registrationFormFields = formatFormFieldsForWorkflow(customFields);

    updateWorkflowRegistration({
      siteId: decodedData.site,
      registrationType:
        decodedData.type === 'player' ? 'PLAYER' : decodedData.role,
      registeredUserId:
        decodedData.type === 'player'
          ? parseInt(selectedPlayer)
          : loggedInUserId,
      registeringUserId: loggedInUserId,
      programId: parseInt(decodedData.prid),
      teamIdOg: parseInt(decodedData.team),
      formFields: registrationFormFields,
    }).then(onNextClick);
  };

  const hasSelectedPlayerError =
    !!existingRegistrationError || (!selectedPlayer && touched);

  return (
    <S.PlayerRegistrationContainer>
      <Card width="100%">
        <S.InfoCardHeader>
          <Typography variant="headline" size="small">
            Registration Info
          </Typography>
          {allowEditMode ? (
            <S.IconContainer onClick={handleEditIconClick}>
              <EditIcon />
            </S.IconContainer>
          ) : null}
        </S.InfoCardHeader>
        <CardBody>
          {editMode ? (
            <>
              {decodedData.type === 'player' ? (
                <S.InputGroupContainer>
                  <S.InputContainer>
                    <Select
                      errorMessage={
                        existingRegistrationError || 'Player is required'
                      }
                      hasError={hasSelectedPlayerError}
                      id="register-player"
                      label="Select player:"
                      required
                      value={selectedPlayer}
                      onChange={handlePlayerSelection}
                      placeholder="Select a player"
                      options={playerOptions}
                      size="medium"
                      width="100%"
                    />
                  </S.InputContainer>
                  <S.ButtonContainer>
                    <Button
                      variant="outline"
                      leftIcon={
                        <ArticleIcon
                          variant="regular"
                          fill={!selectedPlayer ? '#78909C' : 'red'}
                        />
                      }
                      onClick={() => {
                        // TODO: Update to set the edit player modal to open
                        // once endpoint is created to edit a user
                        // setEditPlayerModalOpen(!editPlayerModalOpen);
                        setViewPlayerModalOpen(!viewPlayerModalOpen);
                      }}
                      disabled={!selectedPlayer}
                    >
                      Player Details
                    </Button>
                  </S.ButtonContainer>
                </S.InputGroupContainer>
              ) : null}
              {(!hasSelectedPlayerError && selectedPlayer) ||
              decodedData.type === 'staff' ? (
                <CustomFieldsForm
                  id={formSteps[currentStep]}
                  formFields={Object.values(nonFileUploadFormFields)}
                  onSubmit={handleFormFieldSubmit}
                />
              ) : null}
            </>
          ) : (
            <S.EntriesContainer>
              <RegistrantNameEntry />
              {Object.values(nonFileUploadFormFields).map((field) => (
                <S.FieldEntry key={field.propertyDefinitionId}>
                  <Typography variant="ui" size="large">
                    {field.name}:
                  </Typography>
                  <Typography variant="ui" size="large" weight="bold">
                    {formatFormFieldValue(field)}
                  </Typography>
                </S.FieldEntry>
              ))}
            </S.EntriesContainer>
          )}
          {editMode && allowEditMode ? (
            <S.EditButtonContainer>
              <Button variant="primary" size="medium" onClick={handleSaveEdit}>
                Save registration info
              </Button>
            </S.EditButtonContainer>
          ) : null}
        </CardBody>
      </Card>
      <PlayerDetailsModal
        // TODO: Uncomment once we retrieve the player details and
        // it includes the address details as well.
        // defaultValues={selectedPlayerDetails}
        error={playerDetailsModalError}
        isEditing={editPlayerModalOpen}
        isLoading={playerDetailsModalLoading}
        memberFormFields={memberFormFields}
        memberFormFieldsName={MEMBER_FORM_FIELDS_NAME}
        onOpenChange={(open) => {
          if (!open) {
            setCreatePlayerModalOpen(false);
            setEditPlayerModalOpen(false);
            setPlayerDetailsModalError(undefined);
            handleModalClose();
          }
        }}
        onSubmit={handlePlayerDetailsSubmit}
        open={createPlayerModalOpen || editPlayerModalOpen}
      />
      {selectedPlayerDetails ? (
        <PlayerDetailsViewModal
          onOpenChange={(open) => {
            if (!open) {
              setViewPlayerModalOpen(false);
              handleModalClose();
            }
          }}
          open={viewPlayerModalOpen}
          player={selectedPlayerDetails}
        />
      ) : null}
      <S.PlayerDetailsSnackbar
        description={PLAYER_CREATION_SUCCESS_MESSAGE}
        open={createPlayerSnackbarOpen}
        onOpenChange={setCreatePlayerSnackbardOpen}
      />
    </S.PlayerRegistrationContainer>
  );
};

const RegistrantNameEntry = () => {
  const { decodedData, selectedPlayer, childPlayers } = useRegistration();
  const userName = useAppSelector(getUserName);

  if (decodedData?.type === 'player') {
    const player = childPlayers.find(
      (player) => player.id.toString() === selectedPlayer
    );
    const playerName = `${player?.firstName} ${player?.lastName}`;

    return (
      <S.FieldEntry>
        <Typography variant="ui" size="large">
          Selected player:
        </Typography>
        <Typography variant="ui" size="large" weight="bold">
          {playerName}
        </Typography>
      </S.FieldEntry>
    );
  }

  return (
    <S.FieldEntry>
      <Typography variant="ui" size="large">
        Name:
      </Typography>
      <Typography variant="ui" size="large" weight="bold">
        {userName}
      </Typography>
    </S.FieldEntry>
  );
};
