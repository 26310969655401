import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  Typography,
} from '@la/ds-ui-components';
import { useRegistration } from 'lib/context/RegistrationContext/RegistrationContext';
import { formatFormFieldValue } from 'lib/utils/formField';
import * as S from './RegistrationSuccessCard.styles';

export const RegistrationSuccessCard = () => {
  const navigate = useNavigate();
  const {
    decodedData,
    nonFileUploadFormFields,
    selectedPlayer,
    childPlayers,
    masterProgramName,
  } = useRegistration();
  const [showDetails, setShowDetails] = useState(false);

  const handleOnDetailsClick = () => {
    setShowDetails((show) => !show);
  };

  const handleRosterClick = () => {
    navigate(
      `/app/tournaments/${decodedData?.prid}/teams/${decodedData?.team}/roster`
    );
  };

  const handleDashboardClick = () => {
    navigate(`/dashboard`);
  };

  if (!decodedData) {
    return null;
  }

  const player = childPlayers.find(
    (player) => player.id.toString() === selectedPlayer
  );

  return (
    <S.SuccessCardWrapper $role={decodedData.type}>
      <S.SuccessCardContainer $role={decodedData.type}>
        <Typography variant="headline" size="small">
          You're all set!
        </Typography>
        <Typography variant="ui" size="large">
          You will receive a confirmation email in your inbox shortly. If you
          have any questions, contact <b>support@leagueapps.com</b>
        </Typography>
      </S.SuccessCardContainer>
      {decodedData.type === 'player' ? (
        <S.CardsArea>
          <Card width="100%">
            <CardHeader>{masterProgramName}</CardHeader>
            <CardBody>
              <S.DescriptionContainer>
                <Typography variant="ui" size="medium">
                  <b>{`${player?.firstName} ${player?.lastName}`}</b>{' '}
                  registration
                </Typography>
              </S.DescriptionContainer>
              <S.ButtonContainer>
                <Button
                  variant="outline"
                  onClick={handleOnDetailsClick}
                  rightIcon={
                    <S.AccordionCaretContainer $isOpened={showDetails}>
                      <S.AccordionCaret />
                    </S.AccordionCaretContainer>
                  }
                >
                  {!showDetails ? 'View details' : 'Hide details'}
                </Button>
              </S.ButtonContainer>
            </CardBody>
            {showDetails ? (
              <Table>
                <thead>
                  <TableRow>
                    <TableHeader>Registration field</TableHeader>
                    <TableHeader>Field response</TableHeader>
                  </TableRow>
                </thead>
                <tbody>
                  {Object.values(nonFileUploadFormFields).map((field) => (
                    <TableRow key={field.propertyDefinitionId}>
                      <TableCell>{field.name}</TableCell>
                      <TableCell>{formatFormFieldValue(field)}</TableCell>
                    </TableRow>
                  ))}
                </tbody>
              </Table>
            ) : null}
          </Card>
          <Card width="100%" height="auto">
            <CardHeader>Member dashboard</CardHeader>
            <CardBody>
              <S.DescriptionContainer>
                <Typography variant="ui" size="medium">
                  Now that you are registered you can view your schedule and any
                  other team info in your member dashboard
                </Typography>
              </S.DescriptionContainer>
              <S.ButtonContainer>
                <Button variant="primary" onClick={handleDashboardClick}>
                  Visit dashboard
                </Button>
              </S.ButtonContainer>
            </CardBody>
          </Card>
        </S.CardsArea>
      ) : null}
      {decodedData.type === 'staff' ? (
        <S.StaffCardContainer>
          <Card height="auto">
            <CardHeader>What's next?</CardHeader>
            <CardBody>
              <S.DescriptionContainer>
                <Typography variant="ui" size="medium">
                  Now that you are registered you can begin managing your roster
                  and complete other team rep tasks
                </Typography>
              </S.DescriptionContainer>
              <S.ButtonContainer>
                <Button variant="primary" onClick={handleRosterClick}>
                  Manage roster
                </Button>
                <Button variant="outline" onClick={handleDashboardClick}>
                  View dashboard
                </Button>
              </S.ButtonContainer>
            </CardBody>
          </Card>
        </S.StaffCardContainer>
      ) : null}
    </S.SuccessCardWrapper>
  );
};
