import { useParams } from 'react-router-dom';
import { Typography } from '@la/ds-ui-components';
import { ActivityForm } from './ActivityForm';
import * as S from './ActivityFormWrapper.styles';

export function ActivityFormWrapper() {
  const { teamId } = useParams();
  const { subProgramId } = useParams();
  const originRoute = ['clubteams', subProgramId, 'teams', teamId]
    .filter((s) => s?.toString().length)
    .join('/');
  const editActivity = undefined;
  return (
    <S.ActivityFormContainer>
      <Typography variant="headline" size="large" weight="bold">
        {editActivity ? 'Edit Activity' : 'Create Activity'}{' '}
      </Typography>
      <ActivityForm
        editActivity={editActivity}
        formId="activity-form"
        teamId={teamId}
        subProgramId={subProgramId}
        originRoute={originRoute}
      />
    </S.ActivityFormContainer>
  );
}
