import {
  Modal,
  ModalClose,
  ModalPrimaryActionButton,
  ModalTertiaryActionButton,
} from '@la/ds-ui-components';
import { TableType } from './RosterTable';

/* RosterSection */
export default function DeleteRegistrantModal({
  userId,
  memberName,
  tableType,
  closeModal,
}: {
  userId: number;
  memberName: string;
  tableType: TableType;
  closeModal: () => void;
}) {
  const memberType = tableType === 'players' ? 'player' : 'staff member';

  return (
    <Modal
      hideClose={false}
      open={true}
      onOpenChange={closeModal}
      primaryAction={
        <ModalClose>
          <ModalPrimaryActionButton>{`Remove ${memberType}`}</ModalPrimaryActionButton>
        </ModalClose>
      }
      tertiaryAction={
        <ModalClose>
          <ModalTertiaryActionButton>Cancel</ModalTertiaryActionButton>
        </ModalClose>
      }
      size="small"
      title={`Remove ${memberType} from roster?`}
    >
      Are you sure you want to remove <strong>{memberName}</strong> from your
      roster? You will need to re-send their invitation if you remove them by
      mistake.
    </Modal>
  );
}
/* */
