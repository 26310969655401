import axios from 'axios';
import { extractAccessToken } from 'lib/auth/auth';
import { getBaseURL } from 'lib/utils/urlUtils';
import { ErrorResponse } from './verifyUrlSignature';

const baseUrl = getBaseURL();

const getProgram = async ({
  siteId,
  programId,
}: {
  siteId: string;
  programId: string;
}) => {
  const endpoint = `${baseUrl}/api/sites/${siteId}/programs/${programId}`;
  const token = extractAccessToken();
  try {
    const response = await axios.get(endpoint, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (e) {
    if ((e as ErrorResponse).response.status === 404) {
      return null;
    }
    throw e;
  }
};

export { getProgram };
