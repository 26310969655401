import { Card, CardBody, CardHeader } from '@la/ds-ui-components';
import {
  CustomFieldsForm,
  CustomFieldsFormFields,
} from 'components/RegistrationInfoCard/CustomFieldsForm/CustomFieldsForm';
import { FileUploadFormField } from 'lib/apis/getFormFields';
import { useRegistration } from 'lib/context/RegistrationContext/RegistrationContext';

export const FILE_UPLOAD_FORM_FIELDS_NAME = 'fileUploadFormFilelds';

export const RegistrationFileUpload = () => {
  const {
    currentStep,
    dispatch,
    fileUploadFormFields,
    formSteps,
    groupAccount,
    onNextClick,
    uploadFile,
  } = useRegistration();

  const fields = Object.values(fileUploadFormFields);

  if (!fields.length) {
    return null;
  }

  const handleFileUploadSubmit = ({
    [formSteps[currentStep]]: customFields,
  }: CustomFieldsFormFields): void => {
    const supervisorUserId = groupAccount?.members.find(
      (member) => member.role === 'supervisor'
    )?.user.id;

    const fields = customFields.filter(
      (field): field is FileUploadFormField => field.type === 'FILE_UPLOAD'
    );

    if (supervisorUserId) {
      fields.forEach((field) => {
        const { propertyDefinitionId, value } = field;

        if (!value || !value.file) {
          return;
        }

        uploadFile({
          file: value,
          propertyDefinitionId: propertyDefinitionId.toString(),
          userId: supervisorUserId?.toString(),
        }).then(({ uuid }) => {
          // TODO: Remove once we have tested the full flow
          console.log('uuid', uuid);

          dispatch({
            type: 'SET_FILE_UPLOAD_FORM_FIELD',
            payload: {
              id: field.propertyDefinitionId,
              field: {
                ...field,
                value: { ...value, uuid },
              },
            },
          });
          onNextClick();
        });
      });
    }
  };

  return (
    <Card>
      <CardHeader>Documents</CardHeader>
      <CardBody>
        <CustomFieldsForm
          columns={1}
          gap={24}
          formFields={fields}
          id={formSteps[currentStep]}
          onSubmit={handleFileUploadSubmit}
        />
      </CardBody>
    </Card>
  );
};
