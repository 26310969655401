import { ReactComponent as IconEditLarge } from 'assets/icons/edit_large.svg';
import { ReactComponent as IconPlusLarge } from 'assets/icons/plus_circle_large.svg';
import { AvailableDivisionCardProps } from '../DivisionCard';
import * as S from './DivisionCardInfoSectionRight.styles';

type DivisionCardInfoSectionRightProps = Pick<
  AvailableDivisionCardProps,
  'cardState' | 'onEditDivision' | 'onSelectDivision'
>;

export function DivisionCardInfoRightSection({
  cardState,
  onEditDivision,
  onSelectDivision,
}: DivisionCardInfoSectionRightProps) {
  if (cardState.value === 'unavailable') {
    return null;
  }

  if (cardState.value === 'not-selected') {
    return (
      <S.DivisionCardNotSelectedSectionRight>
        <S.DivisionCardAddDivisionButton
          ariaLabel="Add division"
          icon={<IconPlusLarge />}
          onClick={onSelectDivision}
          size="xl"
          variant="text"
        />
      </S.DivisionCardNotSelectedSectionRight>
    );
  } else if (
    cardState.value === 'not-saved' ||
    cardState.value === 'saved-and-editing'
  ) {
    const numberOfTeamsAdded = cardState.teamSelections.filter(
      (teamSelection) => teamSelection.teamId !== ''
    ).length;

    let numberOfTeamsAddedText;
    if (numberOfTeamsAdded === 1) {
      numberOfTeamsAddedText = '1 team added';
    } else if (numberOfTeamsAdded > 1) {
      numberOfTeamsAddedText = `${numberOfTeamsAdded} teams added`;
    }
    return (
      <S.DivisionCardInfoSectionRight>
        <S.DivisionCardTeamsText>
          {numberOfTeamsAddedText}
        </S.DivisionCardTeamsText>
      </S.DivisionCardInfoSectionRight>
    );
  } else {
    const numberOfTeamsSaved = cardState.savedTeams.length;

    let teamsSavedString = '';
    if (numberOfTeamsSaved > 1) {
      teamsSavedString = `${numberOfTeamsSaved} teams`;
    }
    if (numberOfTeamsSaved === 1) {
      teamsSavedString = '1 team';
    }

    return (
      <S.DivisionCardSavedInfoSectionRight>
        <S.DivisionCardTeamsText>{teamsSavedString}</S.DivisionCardTeamsText>
        <S.DivisionCardEditDivisionButton
          ariaLabel="Edit division"
          icon={<IconEditLarge />}
          onClick={onEditDivision}
          size="xl"
          variant="text"
        />
      </S.DivisionCardSavedInfoSectionRight>
    );
  }
}
