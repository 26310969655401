import {
  BillingSummaryData,
  StoredBankAccount,
  StoredCreditCard,
  CartItemType,
  CartSummaryData,
  WaiverData,
} from '../Checkout.types';
import { PaymentNetwork } from '../PaymentMethodBadge/PaymentMethodBadgeConfig';

export const mockBankAccount: StoredBankAccount = {
  paymentType: 'ACH',
  storedBankAccountId: 5551212,
  accountType: 'checking',
  bankName: 'First Bank of Astoria',
  createdOn: '2022-10-27T17:18:00',
  last4Digits: '6729',
  isPrimaryPaymentOption: false,
  paymentMethodId: 5551212,
};

export const mockCreditCard: StoredCreditCard = {
  cardType: 'STORED',
  paymentType: 'CARD',
  storedCreditCardId: 9998765,
  createdOn: '2022-10-25T09:34:11',
  expirationDate: '2024-10-01T00:00:00',
  last4Digits: '0000',
  paymentNetwork: PaymentNetwork.Visa,
  isPrimaryPaymentOption: false,
  paymentMethodId: 9998765,
};

export const mockPaymentMethodInfo = {
  storedBankAccounts: [{ ...mockBankAccount, isPrimaryPaymentOption: true }],
  storedCreditCards: [mockCreditCard],
  paymentOptionsCount: 2,
};

export const mockBankAccountPaymentMethodInfo = {
  storedBankAccounts: [{ ...mockBankAccount, isPrimaryPaymentOption: true }],
  paymentOptionsCount: 1,
};

export const mockCreditCardPaymentMethodInfo = {
  storedCreditCards: [{ ...mockCreditCard, isPrimaryPaymentOption: true }],
  paymentOptionsCount: 1,
};

export const mockCartSummaryData: CartSummaryData = {
  cartUuid: '54650607-a632-41fd-9aa1-8bc24cf4d12f',
  subtotal: '2000.00',
  currency: 'USD',
  cartItems: [
    {
      cartItemUuid: '0188d929-9098-7d00-bc29-6e340993ca00',
      cartItemType: CartItemType.TournamentTeamRegistration,
      editUrl:
        '/someroute/registrations/edit?cartItemId=13&cartItemUuid=0188d929-9098-7d00-bc29-6e340993ca00',
      createdBy: 0,
      createdOn: '2023-06-20T14:15:59.900420Z',
      lastUpdatedBy: 0,
      lastUpdatedOn: '2023-06-20T14:15:59.900420Z',
      subtotal: '2000.00',
      currency: 'USD',
      options: {
        program: {
          currency: 'USD',
          basePrice: '500.00',
          programId: 457,
          locationId: 789,
          endDate: '2023-06-25T00:00:00Z',
          programName: 'Special Cup',
          programType: 'Tournament',
          locationName: 'Greenwich Skating Club',
          startDate: '2023-06-21T00:00:00Z',
          isGroupedProgram: true,
        },
        subprograms: {
          label: 'Division',
          details: [
            {
              currency: 'USD',
              basePrice: '500.00',
              registrations: [
                {
                  teamId: 111,
                  teamName: '8u Garcia',
                  registrationId: 12349,
                },
              ],
              subprogramId: 1234,
              subprogramName: 'Mites A',
            },
            {
              currency: 'USD',
              basePrice: '500.00',
              registrations: [
                {
                  teamId: 333,
                  teamName: '9u Smith',
                  registrationId: 12350,
                },
                {
                  teamId: 555,
                  teamName: '9u Jackson',
                  registrationId: 12351,
                },
              ],
              subprogramId: 1235,
              subprogramName: 'Squirts A',
            },
            {
              currency: 'USD',
              basePrice: '500.00',
              registrations: [
                {
                  teamId: 444,
                  teamName: '10u Garcia',
                  registrationId: 12352,
                },
              ],
              subprogramId: 1236,
              subprogramName: 'Pee Wee A',
            },
          ],
        },
      },
    },
  ],
};

export const mockEmptyCartSummaryData = {
  cartItems: [],
  cartUuid: '546039-42fd-9aa',
  currency: 'USD',
  subtotal: '0.00',
};

export const mockCheckoutBillingSummary: BillingSummaryData = {
  cartUuid: '54650607-a632-41fd-9aa1-8bc24cf4d12f',
  currency: 'USD',
  dueToday: '4228.32',
  totalAmount: '4100.00',
  transactionFees: '128.32',
  summary: [
    {
      cartItemName: 'Special Cup',
      cartItemType: CartItemType.TournamentTeamRegistration,
      dueToday: '2000.00',
      totalAmount: '2000.00',
      description: 'Teams: 4',
      paymentTerm: 'FULL',
      paymentTermLabel: 'Pay in full',
    },
    {
      cartItemName: 'Ice Cup',
      cartItemType: CartItemType.TournamentTeamRegistration,
      dueToday: '2100.00',
      totalAmount: '2100.00',
      description: 'Teams: 4',
      paymentTerm: 'FULL',
      paymentTermLabel: 'Pay in full',
    },
  ],
};

export const mockCheckoutBillingSummaryWithPaymentPlan: BillingSummaryData = {
  cartUuid: '54650607-a632-41fd-9aa1-8bc24cf4d44f',
  currency: 'USD',
  dueToday: '1128.32',
  totalAmount: '2000.00',
  transactionFees: '128.32',
  summary: [
    {
      cartItemName: 'Special Cup',
      cartItemType: CartItemType.TournamentTeamRegistration,
      dueToday: '1000.00',
      totalAmount: '2000.00',
      description: 'Teams: 4',
      paymentTerm: 'PAYMENT_PLAN',
      paymentTermLabel: 'Pay in 2',
    },
  ],
};

export const mockCheckoutWaivers: WaiverData[] = [
  {
    content:
      'I hereby waive all liability from the sport organizer. I will participate at my own risk.',
    name: 'Payment Waiver',
    programId: 2343567,
    waiverId: 5787444,
    waiverType: 'PAYMENT',
  },
  {
    content:
      'I hereby waive all liability from the sport organizer. I will participate at my own risk.',
    name: 'Registration Waiver',
    programId: 3265833,
    waiverId: 5787501,
    waiverType: 'REGISTRATION',
  },
];
