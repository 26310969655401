import { CartItemData } from '../Checkout.types';
import CheckoutCard, {
  CheckoutCardBody, // CheckoutCardFooter,
  // CheckoutCardPrimaryButton,
} from '../CheckoutCard/CheckoutCard';
import * as S from './PaymentTermsCard.styles';

export type PaymentTermsItem = {
  tournamentName: string;
  paymentDetail: string;
};

export type PaymentTermsData = PaymentTermsItem[];

export type PaymentTermsCardProps = { cartItems: CartItemData[] };

/*PaymentTermsCard */
export default function PaymentTermsCard({ cartItems }: PaymentTermsCardProps) {
  const paymentTermsData = cartItems.map((cartItem) => {
    const tournamentName = cartItem.options.program.programName;
    //TODO should get the paymentDetail data from cartItem data
    const paymentDetail = 'Pay in full';
    return { tournamentName: tournamentName, paymentDetail: paymentDetail };
  }, []);

  return (
    <CheckoutCard cardName="Payment terms">
      <CheckoutCardBody>
        <PaymentTermsBody paymentTermsData={paymentTermsData} />
      </CheckoutCardBody>
      {/*TODO hide Edit payment terms button for MVP */}
      {/* <CheckoutCardFooter>
        <CheckoutCardPrimaryButton
          label="Edit payment terms"
          clickHandler={() => {}}
        />
      </CheckoutCardFooter> */}
    </CheckoutCard>
  );
}
/* */

/*PaymentTermsBody */
function PaymentTermsBody({
  paymentTermsData,
}: {
  paymentTermsData: PaymentTermsData;
}) {
  return (
    <S.PaymentTermsBody>
      <PaymentTermsItems paymentTermsData={paymentTermsData} />
    </S.PaymentTermsBody>
  );
}
/* */

/*PaymentTermsItems */
function PaymentTermsItems({
  paymentTermsData,
}: {
  paymentTermsData: PaymentTermsData;
}) {
  return (
    <>
      {paymentTermsData.map(({ tournamentName, paymentDetail }, idx) => (
        <S.PaymentTermsItem key={`paymentTermsItem-${idx}`}>
          <S.TournamentName>{tournamentName}</S.TournamentName>
          <S.PaymentDetail>{paymentDetail}</S.PaymentDetail>
        </S.PaymentTermsItem>
      ))}
    </>
  );
}
/* */
