import styled from 'styled-components';
import { ThemedComponent } from '@la/ds-ui-components';
import { media } from 'lib/media-queries/mixins';
import { SITE_HEADER_HEIGHT } from 'domains/Shell/Header/Header.styles';

type ComposedHeaderStyleProps = ThemedComponent<{
  $isTabletLandscapeUp: boolean;
}>;

export const RegistrationContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  width: 100%;
  max-width: 920px;
  margin: 20px 0;
  padding: 0 16px;

  & > h4 {
    margin-bottom: 16px;
  }
`;

export const StepContainer = styled.div`
  align-self: flex-start;
`;

export const OutletContainer = styled.div`
  margin-bottom: 60px;
  width: 100%;
`;

export const BottomDrawer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.05);
  border-top: 1px solid;
  border-color: var(--primary-500);
  background-color: white;
  z-index: 1000;
`;

export const DrawerContent = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 12px 16px;
`;

export const ForwardButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  ${media.TabletPortraitDown`
    width: 75%;
    & div {
      width: 100%;
    }
  `}
`;

export const BackButtonContainer = styled.div<{ $disabled: boolean }>`
  display: flex;
  justify-content: flex-end;

  ${media.TabletPortraitDown`
    width: 25%;
    & div {
      width: 100%;
    }
  `}
  & svg > polyline {
    stroke: ${({ $disabled }) =>
      $disabled ? 'var(--blue-grey-400)' : 'var(--blue-grey-900)'};
  }
`;

export const ComposedHeaderContainer = styled.div`
  width: 100%;
  background: var(--primary-35);
  padding: 8px 12px;
  font-size: 18px;
  line-height: 24px;
  border-bottom: 1px solid var(--primary-100);
  z-index: 1;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  gap: 0;

  ${media.TabletLandscapeUp`
    top: ${SITE_HEADER_HEIGHT}px;
    flex-direction: row;
    gap: 24px;
  `}
`;

export const ComposedHeaderDate = styled.div<ComposedHeaderStyleProps>(
  ({ theme, $isTabletLandscapeUp }: ComposedHeaderStyleProps) => {
    const font = $isTabletLandscapeUp
      ? theme.typography.ui.xl
      : theme.typography.ui.large;
    return {
      ...font,
      display: 'flex',
      alignItems: 'center',
      gap: '4px',

      '& > svg': {
        flexShrink: 0,
      },
    };
  }
);
