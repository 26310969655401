import styled from 'styled-components';
import { generateInputBaseDynamicStyles, generateInputAdornmentDynamicStyles } from 'theming/tokens/components/input';
import { inputVariantHeights } from 'components/InputBase/InputBase.styles';
import { getInputContainerWidth } from 'components/InputBase/InputBase.utils';
export const InputControlWrapper = styled.div.withConfig({
  componentId: "sc-g68v9m-0"
})(({
  theme,
  $isInteractive,
  $isHorizontal,
  $marginLeft
}) => {
  const {
    spacing
  } = theme;
  const cursor = $isInteractive ? 'pointer' : 'not-allowed';
  return {
    display: 'flex',
    alignItems: 'flex-start',
    columnGap: $isHorizontal ? spacing.large : 0,
    maxWidth: '100%',
    flexDirection: $isHorizontal ? 'row' : 'column',
    margin: 0,
    padding: 0,
    marginLeft: $marginLeft && !$isHorizontal ? `${$marginLeft + 24}px` : 0,
    cursor,
    '& > label': {
      pointerEvents: $isInteractive ? 'auto' : 'none'
    }
  };
});
export const InputContainerWrapper = styled.div.withConfig({
  componentId: "sc-g68v9m-1"
})(({
  $width
}) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: getInputContainerWidth($width),
    flexBasis: 1,
    flexGrow: 1
  };
});
export const InputContainer = styled.div.withConfig({
  componentId: "sc-g68v9m-2"
})(({
  theme,
  $size,
  $isInteractive,
  $state,
  $isHorizontal
}) => {
  const styles = generateInputBaseDynamicStyles(theme, $state);
  return {
    position: 'relative',
    display: 'flex',
    boxSizing: 'border-box',
    cursor: 'pointer',
    padding: '0 8px',
    margin: 0,
    marginTop: $isHorizontal ? '4px' : 0,
    minWidth: 0,
    width: '100%',
    height: inputVariantHeights[$size],
    backgroundColor: styles.backgroundColor,
    border: '1px solid',
    borderRadius: '4px',
    borderColor: styles.borderColor,
    pointerEvents: $isInteractive ? 'auto' : 'none',
    '&:focus-within': {
      outline: '2px solid',
      outlineOffset: '2px',
      outlineColor: styles.borderColor
    }
  };
});
export const InputHeaderContainer = styled.div.withConfig({
  componentId: "sc-g68v9m-3"
})(({
  theme,
  $isHorizontal,
  $hasCharacterCounter
}) => {
  const {
    spacing
  } = theme;
  return {
    display: 'flex',
    alignItems: $isHorizontal ? 'center' : 'flex-end',
    justifyContent: $isHorizontal ? 'center' : 'space-between',
    flexBasis: 1,
    marginTop: $isHorizontal && $hasCharacterCounter ? spacing.large : 0,
    width: '100%',
    height: '100%',
    textAlign: 'center'
  };
});
export const InputHeaderLeftContainer = styled.div.withConfig({
  componentId: "sc-g68v9m-4"
})(({
  theme,
  $isHorizontal,
  $width
}) => {
  const {
    spacing
  } = theme;
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: spacing.xxxs,
    whiteSpace: 'nowrap',
    height: '100%',
    width: !$isHorizontal ? 'auto' : $width ?? 'auto',
    marginTop: $isHorizontal ? spacing.xxs : 0
  };
});
export const AdornmentWrapper = styled.div.withConfig({
  componentId: "sc-g68v9m-5"
})(({
  theme,
  $size,
  $state
}) => {
  const styles = generateInputAdornmentDynamicStyles(theme, $state);
  const iconSize = $size === 'large' ? '20px' : '16px';
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 0 4px 0',
    '& svg': {
      fill: styles.fill,
      stroke: styles.fill,
      height: iconSize,
      width: iconSize
    }
  };
});