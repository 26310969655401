import { ReactNode, forwardRef, useEffect, useRef, useState } from 'react';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import { Portal, Root } from '@radix-ui/react-select';
import {
  CaretDownIcon,
  InputErrorMessage,
  InputLabel,
  InputSizeVariant,
} from '@la/ds-ui-components';
import { useFormContext } from './utils/FormContext';
import {
  createAssignSharedRefs,
  determineComponentState,
  useInputState,
} from './utils/SelectPopover.utils';
import * as S from './SelectPopover.styles';

export interface SelectOption {
  value: string;
  label: string;
  size?: InputSizeVariant;
  disabled?: boolean;
}

export type SelectPopoverProps = {
  /**
   * aria-label value applied to the trigger
   */
  ariaLabel?: string;
  /**
   * disabled state
   */
  disabled?: boolean;
  /**
   * error state
   */
  hasError?: boolean;
  /**
   * id applied to the trigger
   */
  id: string;
  /**
   * label value applied to the root
   */
  label?: string;
  /**
   * name applied to the root
   */
  name?: string;
  /**
   * event handler called when the value changes
   */
  onChange?: (value: string) => void;
  /**
   * content to be rendered
   */
  content: ReactNode;
  /**
   * placeholder displayed when no option is selected
   */
  placeholder: string;
  /**
   * read only state
   */
  readOnly?: boolean;
  /**
   * required stat
   */
  required?: boolean;
  /**
   * input's size. Medium or Large
   */
  size?: InputSizeVariant;
  /**
   * input's width. Defaults to 100%
   */
  width?: string | number;
  /**
   * the current selected value
   */
  value?: string;
  /**
   * the default selected value
   */
  defaultValue?: string;
  /**
   * the controlled open state of the select. Must be used in conjunction with onOpenChange
   */
  open?: boolean;
  /**
   * event handler called when the open state of the select changes.
   */
  onOpenChange?: (open: boolean) => void;
  /**
   * the initial open state; Defaults to false.
   */
  defaultOpen?: boolean;
  /**
   * A test ID to use to target the select trigger in unit tests
   */
  testId?: string;
  /**
   * If true, the text '(optional)' is shown next to the label
   */
  optional?: boolean;
  /**
   * Optional error message to be displayed when `hasError = true`
   */
  errorMessage?: string;
};

export const SelectPopover = forwardRef<HTMLButtonElement, SelectPopoverProps>(
  (
    {
      ariaLabel,
      id,
      label,
      content,
      placeholder,
      hasError,
      errorMessage,
      disabled,
      readOnly,
      size,
      width = '100%',
      value,
      open,
      onOpenChange,
      defaultOpen,
      onChange,
      required,
      optional,
      testId = id,
    },
    ref
  ) => {
    const [internalValue, setInternalValue] = useState('');
    const [internalOpen, setInternalOpen] = useState(!!open);

    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const internalRef = useRef<HTMLButtonElement | null>(null);
    const { isFocused, isHovered } = useInputState(internalRef);
    const context = useFormContext();

    const isInteractive = !Boolean(disabled || readOnly);

    const assignSharedRefs = createAssignSharedRefs<HTMLButtonElement>(
      ref,
      internalRef
    );

    // We need to make every select controlled; See: https://github.com/radix-ui/primitives/issues/1658
    const handleOpenChange = (v: boolean) => {
      const handler = () => {
        setInternalOpen(v);
        if (onOpenChange) {
          onOpenChange(v);
        }
      };
      // Timeout isn't being cleared inside an effect for 3 main reasons:
      // - This is a temporary fix: https://github.com/radix-ui/primitives/pull/2085;
      // - The chances of this timeout not being cleared are minimal;
      // - Even in the abismal chance of not being executed within the component life cycle, isn't an expensive operation;
      setTimeout(handler, 0);
    };

    useEffect(() => {
      const isClickedOutside = (e: MouseEvent) => {
        if (
          wrapperRef.current &&
          !wrapperRef.current.contains(e.target as Node) &&
          !assignSharedRefs
        ) {
          setInternalOpen(false);
        }
      };
      window.addEventListener('click', isClickedOutside);
      return () => {
        window.removeEventListener('click', isClickedOutside);
      };
    }, [assignSharedRefs]);

    const handleValueChange = (v: string) => {
      // If the input is not controlled by the user we need to make it internally controlled
      // https://github.com/radix-ui/primitives/issues/1569
      if (!value) {
        setInternalValue(v);
      }
      if (onChange) {
        onChange(v);
      }
    };

    const rootProps = {
      value: value ?? internalValue,
      onValueChange: handleValueChange,
      open: open ?? internalOpen,
      onOpenChange: handleOpenChange,
      defaultOpen,
    };

    const renderValue = () => {
      return value && value !== '' ? value : `${placeholder}...`;
    };

    const inputState = determineComponentState({
      error: hasError,
      disabled,
      readOnly,
      isFocused,
      isHovered,
    });

    const getSize = () => {
      if (size) {
        return size;
      }

      if (context?.inputSize) {
        return context.inputSize;
      }

      return 'large';
    };

    const getTitle = () => {
      if (!internalValue && !value) {
        return placeholder;
      }

      if (value) {
        return value;
      }

      if (internalValue) {
        return internalValue;
      }
    };

    const hasErrorMessage = inputState === 'error' && errorMessage;

    return (
      <S.SelectWrapper $isInteractive={isInteractive} ref={wrapperRef}>
        <Root {...rootProps}>
          {!!label ? (
            <InputLabel
              htmlFor={id}
              inputState={inputState}
              optional={optional}
              required={required}
            >
              {label}
            </InputLabel>
          ) : null}
          <S.SelectTrigger
            ref={assignSharedRefs}
            id={id}
            aria-label={ariaLabel}
            disabled={disabled}
            data-testid={testId}
            $width={width}
            $size={getSize()}
            $state={inputState}
            $isInteractive={isInteractive}
          >
            <S.SelectValueContainer
              $isPlaceholder={!internalValue && !value}
              $state={inputState}
              title={getTitle()}
            >
              <span> {renderValue()}</span>
            </S.SelectValueContainer>
            <S.ChevronWrapper $state={inputState}>
              <CaretDownIcon />
            </S.ChevronWrapper>
          </S.SelectTrigger>
          <Portal>
            <S.SelectContent position="popper" avoidCollisions={true}>
              <ScrollArea.Root type="scroll">
                <S.SelectViewport asChild>
                  <ScrollArea.Viewport
                    role="listbox"
                    style={{ overflowY: undefined }}
                  >
                    {content}
                  </ScrollArea.Viewport>
                </S.SelectViewport>
                <S.Scrollbar orientation="vertical">
                  <S.ScrollbarThumb />
                </S.Scrollbar>
              </ScrollArea.Root>
            </S.SelectContent>
          </Portal>
        </Root>
        {hasErrorMessage ? (
          <InputErrorMessage>{errorMessage}</InputErrorMessage>
        ) : null}
      </S.SelectWrapper>
    );
  }
);
