import { getSummaryData } from 'redux/checkoutSlice';
import { useAppSelector } from 'redux/store';
import CheckoutBillingSummary from '../CheckoutBillingSummary/CheckoutBillingSummary';
import { CheckoutCardHeader } from '../CheckoutCard/CheckoutCard';
import * as S from './ConfirmationRollup.styles';

/* ConfirmationRollup */
export default function ConfirmationRollup() {
  const billingSummary = useAppSelector(getSummaryData);

  return (
    <S.ConfirmationRollup>
      <CheckoutCardHeader name="Your order" />
      {billingSummary ? (
        <CheckoutBillingSummary
          billingSummaryData={billingSummary}
          summaryType="confirmation"
        />
      ) : null}
    </S.ConfirmationRollup>
  );
}
/* */
