import { useCallback } from 'react';
import { Button, Typography } from '@la/ds-ui-components';
import { getLAHostnameParts } from 'lib/utils/urlUtils';
import { isGameActivity } from '../ActivityForm';
import { Activity } from '../openapi-client/models';
import { TeamWithProps } from '../utils/ActivityForm.utils';
import * as S from './ActivityFormConfirmation.styles';

type ActivityFormConfirmationProps = {
  confirmation: { activity?: Activity[]; errorMessage?: string };
  teams: TeamWithProps[];
  originRoute: string;
};
export function ActivityFormConfirmation({
  confirmation,
  teams,
  originRoute,
}: ActivityFormConfirmationProps) {
  const formatActivity = useCallback(() => {
    const { activity, errorMessage } = confirmation;

    if (activity?.length && !errorMessage) {
      const type = isGameActivity(activity[0].type)
        ? 'game'
        : activity[0].type.split('_')[1];

      //recurring activitites
      const recurring = activity.every(
        ({ origin, seqToken }) => origin === 'generated' && !!seqToken
      );
      const activityCount = activity.length;
      const activityCountType = `${activityCount} ${
        recurring ? 'recurring' : ''
      } ${activity.length === 1 ? `${type} has` : `${type}s have`}`;

      const homeTeam =
        activity[0].teams.find(({ homeAway }) => homeAway === 'home') ??
        activity[0].teams[0];
      const homeTeamName = teams.find(
        ({ id }) => id === homeTeam?.teamId
      )?.name;

      const awayTeam = activity[0].teams.find(
        ({ homeAway }) => homeAway === 'away'
      );
      const awayTeamName = teams.find(
        ({ id }) => id === awayTeam?.teamId
      )?.name;

      return `${activityCountType} been been created for ${homeTeamName}${
        awayTeamName ? ` vs ${awayTeamName}.` : '.'
      } View schedule to see new activity details.`;
    } else {
      return `${errorMessage}. Please refresh this page and try again.`;
    }
  }, [confirmation, teams]);

  const handleOnViewSchedule = () => {
    const { sld, subdomain, tld } = getLAHostnameParts();
    window.location.href = `https://${subdomain}.${sld}.${tld}/${originRoute}`;
  };

  return (
    <>
      <S.ConfirmationMessage $error={!!confirmation.errorMessage}>
        <Typography variant="ui" size="xl">
          {formatActivity()}
        </Typography>
      </S.ConfirmationMessage>
      <Button variant="primary" size="large" onClick={handleOnViewSchedule}>
        View Schedule
      </Button>
    </>
  );
}
