import { DateTime } from 'luxon';
import { RadioButton } from 'components/RadioButton/RadioButton';
import { useCheckoutInfo } from 'lib/context/CheckoutInfoContext';
import { capitalizeFirstLetter } from 'lib/utils/stringUtils';
import {
  PaymentMethod,
  StoredBankAccount,
  StoredPaymentMethod,
} from '../Checkout.types';
import { paymentMethodBadgeConfig } from './PaymentMethodBadgeConfig';
import * as S from './PaymentMethodBadge.styles';

export type PaymentMethodBadgeProps = {
  isSelectedPaymentMethod: boolean;
  paymentMethod: StoredPaymentMethod;
};

export function isBankAccount(
  storedType: PaymentMethod
): storedType is StoredBankAccount {
  return (storedType as StoredBankAccount).paymentType === 'ACH';
}

/* PaymentMethodBadge */
export default function PaymentMethodBadge({
  isSelectedPaymentMethod,
  paymentMethod,
}: PaymentMethodBadgeProps) {
  const { updateSelectedPaymentMethod } = useCheckoutInfo();
  let paymentMethodDetailConfig = paymentMethodBadgeConfig.bank;

  const isPaymentMethodBank = isBankAccount(paymentMethod);
  const cardExpirationDate = isPaymentMethodBank
    ? ''
    : DateTime.fromISO(paymentMethod.expirationDate).toFormat('MM/yy');
  if (isPaymentMethodBank) {
    paymentMethodDetailConfig = paymentMethodBadgeConfig.bank;
  } else {
    const network = paymentMethod.paymentNetwork;
    const creditCard = paymentMethodBadgeConfig.creditCard;
    paymentMethodDetailConfig = creditCard[network];
  }

  const setSelectedPaymentMethod = () => {
    updateSelectedPaymentMethod(paymentMethod);
  };

  return (
    <S.PaymentMethodBadge
      $paymentMethodDetailConfig={paymentMethodDetailConfig}
      $isSelectedOption={isSelectedPaymentMethod}
      data-testid="payment_badge"
      onClick={setSelectedPaymentMethod}
    >
      <S.PaymentMethodBadgePrimaryRow>
        {paymentMethodDetailConfig ? (
          <img
            src={paymentMethodDetailConfig.logo}
            alt={paymentMethodDetailConfig.name}
          />
        ) : null}

        <RadioButton
          checked={isSelectedPaymentMethod}
          handleChange={setSelectedPaymentMethod}
          iconColor={
            isPaymentMethodBank ? paymentMethodDetailConfig.textColor : null
          }
        />
      </S.PaymentMethodBadgePrimaryRow>
      <S.PaymentMethodDetail>
        <S.PaymentMethodBadgeRow>
          <span>
            {isPaymentMethodBank
              ? capitalizeFirstLetter(paymentMethod.accountType)
              : 'Card'}{' '}
            ending in {paymentMethod.last4Digits}
          </span>
        </S.PaymentMethodBadgeRow>
        <S.PaymentMethodBadgeRow>
          {/*TODO should replace the placeholder name with the real username  */}
          <span>John Smith</span>
          <span>{cardExpirationDate}</span>
        </S.PaymentMethodBadgeRow>
      </S.PaymentMethodDetail>
    </S.PaymentMethodBadge>
  );
}
/* */
