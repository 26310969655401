import axios from 'axios';
import { extractAccessToken } from 'lib/auth/auth';
import { getBaseURL } from 'lib/utils/urlUtils';

export type User = {
  firstName: string;
  lastName: string;
  email?: string;
  gender?: 'Male' | 'Female';
  birthdate?: string | null; // YYYY-MM-DD
};

export type Address = {
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zipCode: string;
};

export type Player = {
  user: User;
  address: Address;
};

export type PlayerParameters = {
  siteDomain: string;
  player: Player;
};

const baseUrl = getBaseURL();

const createPlayer = async ({ siteDomain, player }: PlayerParameters) => {
  const endpoint = `${baseUrl}/api/member-portal/${siteDomain}/player`;
  const token = extractAccessToken();
  const response = await axios.post(endpoint, player, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const editPlayer = async ({ siteDomain, player }: PlayerParameters) => {
  const endpoint = `${baseUrl}/api/member-portal/${siteDomain}/player`;
  const token = extractAccessToken();
  const response = await axios.put(endpoint, player, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export { createPlayer, editPlayer };
