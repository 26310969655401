import { Item } from '@radix-ui/react-select';
import styled from 'styled-components';
export const SelectItemWrapper = styled.div.withConfig({
  componentId: "sc-8ffaax-0"
})(({
  $isDisabled
}) => {
  return {
    cursor: $isDisabled ? 'not-allowed' : 'pointer'
  };
});
export const SelectItemContainer = styled(Item).withConfig({
  componentId: "sc-8ffaax-1"
})(({
  theme,
  $isPlaceholder,
  $size = 'large'
}) => {
  const fontStyle = $isPlaceholder ? 'italic' : 'normal';
  return {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    outline: 'none',
    fontStyle,
    ...theme.typography.ui[$size],
    '&[data-highlighted]': {
      fontStyle,
      backgroundColor: theme.palette.foundational.blueGrey[50]
    },
    '&[data-disabled]': {
      color: theme.palette.foundational.blueGrey[250],
      pointerEvents: 'none'
    }
  };
});
export const SelectItemTextContainer = styled.span.withConfig({
  componentId: "sc-8ffaax-2"
})(({
  $grouped,
  $isPlaceholder
}) => {
  const padding = $grouped && !$isPlaceholder ? '9px 32px' : '9px 16px';
  return {
    width: 'var(--radix-select-trigger-width)',
    padding,
    boxSizing: 'border-box',
    wordBreak: 'break-word'
  };
});