import { Button, Typography } from '@la/ds-ui-components';
import RosterTable, { Registrant } from './RosterTable';
import * as S from './RosterManagement.styles';

export type RosterSectionData = {
  openModal: () => void;
  rosterData: Registrant[];
  rosterTitle: string;
  rosterType: 'players' | 'staff';
  tableTitleSize: 'medium' | 'large';
};

/* RosterSection */
export default function RosterSection({
  openModal,
  rosterData,
  rosterTitle,
  rosterType,
  tableTitleSize,
}: RosterSectionData) {
  const inviteType = rosterType === 'players' ? 'player' : 'staff';

  return (
    <S.RosterSection>
      <S.SectionHead>
        <Typography variant="headline" size={tableTitleSize}>
          {rosterTitle}
        </Typography>
        {rosterData.length > 0 ? (
          <Button size="large" onClick={openModal}>
            Get {inviteType} invite link
          </Button>
        ) : null}
      </S.SectionHead>
      {rosterData.length > 0 ? (
        <RosterTable tableType={rosterType} registrants={rosterData} />
      ) : (
        <EmptyRosterNotice
          inviteType={inviteType}
          rosterType={rosterType}
          openModal={openModal}
        />
      )}
    </S.RosterSection>
  );
}
/* */

export function EmptyRosterNotice({
  inviteType,
  rosterType,
  openModal,
}: {
  inviteType: 'player' | 'staff';
  rosterType: 'players' | 'staff';
  openModal: () => void;
}) {
  return (
    <S.EmptyRosterNotice>
      <Typography variant="headline" size="small">
        You don't have any {rosterType} in your roster.
      </Typography>
      <Typography variant="ui" size="large" weight="regular">
        Get started by inviting {rosterType}.
      </Typography>
      <Button size="large" onClick={openModal}>
        Get {inviteType} invite link
      </Button>
    </S.EmptyRosterNotice>
  );
}
