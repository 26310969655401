import {
  createContext,
  useState,
  useContext,
  PropsWithChildren,
  useReducer,
} from 'react';
import {
  PaymentMethod,
  PaymentMethodType,
} from 'domains/Checkout/Checkout.types';

type CheckoutFieldErrorStates = {
  hasPaymentMethodError?: boolean;
  hasPaymentPolicyError?: boolean;
};

export type CheckoutInfo = {
  checkoutErrorsAreVisible: boolean;
  checkoutFieldErrorStates: CheckoutFieldErrorStates;
  hasAgreedToPaymentPolicy: boolean;
  hasPaymentWaiver: boolean;
  selectedPaymentMethod: PaymentMethod;
  submissionErrorsAreVisible: boolean;
  updateCheckoutErrorsAreVisible: () => void;
  updateHasAgreedToPaymentPolicy: (checked: boolean) => void;
  updateHasPaymentWaiver: (hasPaymentWaiver: boolean) => void;
  updateSelectedPaymentMethod: (paymentMethod: PaymentMethod) => void;
  updateSubmissionErrorsAreVisible: (displayError: boolean) => void;
  updatePaymentMethodErrors: (displayError: boolean) => void;
  updatePaymentWaiverErrors: (displayError: boolean) => void;
};

const checkoutFieldErrorStatesDefaultValues: CheckoutFieldErrorStates = {
  hasPaymentMethodError: undefined,
  hasPaymentPolicyError: undefined,
};

export const checkoutInfoDefaultValues: CheckoutInfo = {
  checkoutErrorsAreVisible: false,
  checkoutFieldErrorStates: checkoutFieldErrorStatesDefaultValues,
  hasAgreedToPaymentPolicy: false,
  hasPaymentWaiver: false,
  selectedPaymentMethod: null,
  submissionErrorsAreVisible: false,
  updateCheckoutErrorsAreVisible: () => {},
  updateHasAgreedToPaymentPolicy: (checked: boolean) => {},
  updateHasPaymentWaiver: (hasPaymentWaiver: boolean) => {},
  updateSelectedPaymentMethod: (paymentMethod: PaymentMethod | null) => {},
  updateSubmissionErrorsAreVisible: (displayError: boolean) => {},
  updatePaymentMethodErrors: (displayError: boolean) => {},
  updatePaymentWaiverErrors: (displayError: boolean) => {},
};

function checkoutErrorsReducer(
  checkoutFieldErrorStates: CheckoutFieldErrorStates,
  { type, hasError }: { type: 'method' | 'policy'; hasError: boolean }
) {
  switch (type) {
    case 'method':
      return {
        ...checkoutFieldErrorStates,
        hasPaymentMethodError: hasError,
      };
    case 'policy':
      return {
        ...checkoutFieldErrorStates,
        hasPaymentPolicyError: hasError,
      };
    default:
      return checkoutFieldErrorStates;
  }
}

const CheckoutInfoContext = createContext<CheckoutInfo>(
  checkoutInfoDefaultValues
);

const CheckoutInfoProvider = ({ children }: PropsWithChildren) => {
  const [checkoutErrorsAreVisible, setCheckoutErrorsAreVisible] =
    useState<boolean>(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethod | null>(
      checkoutInfoDefaultValues.selectedPaymentMethod
    );
  const [hasPaymentWaiver, setHasPaymentWaiver] = useState<boolean>(
    checkoutInfoDefaultValues.hasPaymentWaiver
  );
  const [submissionErrorsAreVisible, setSubmissionErrorsAreVisible] =
    useState<boolean>(checkoutInfoDefaultValues.submissionErrorsAreVisible);
  const [hasAgreedToPaymentPolicy, setHasAgreedToPaymentPolicy] =
    useState<boolean>(checkoutInfoDefaultValues.hasAgreedToPaymentPolicy);
  const [checkoutFieldErrorStates, dispatch] = useReducer(
    checkoutErrorsReducer,
    checkoutInfoDefaultValues.checkoutFieldErrorStates
  );

  function updateCheckoutErrorsAreVisible() {
    setCheckoutErrorsAreVisible(true);
  }

  function updateHasAgreedToPaymentPolicy(hasAgreed: boolean) {
    setHasAgreedToPaymentPolicy(hasAgreed);
    updatePaymentWaiverErrors(!hasAgreed);
  }

  function updateSelectedPaymentMethod(paymentMethod: PaymentMethod) {
    setSelectedPaymentMethod(paymentMethod);
    updatePaymentMethodErrors(!paymentMethod);
  }
  function updatePaymentMethodErrors(displayError: boolean) {
    dispatch({ type: 'method', hasError: displayError });
  }

  function updateHasPaymentWaiver(hasWaiver: boolean) {
    setHasPaymentWaiver(hasWaiver);
  }

  function updatePaymentWaiverErrors(displayError: boolean) {
    dispatch({ type: 'policy', hasError: displayError });
  }

  function updateSubmissionErrorsAreVisible(displayError: boolean) {
    setSubmissionErrorsAreVisible(displayError);
  }

  return (
    <CheckoutInfoContext.Provider
      value={{
        checkoutErrorsAreVisible: checkoutErrorsAreVisible,
        checkoutFieldErrorStates: checkoutFieldErrorStates,
        hasAgreedToPaymentPolicy: hasAgreedToPaymentPolicy,
        hasPaymentWaiver: hasPaymentWaiver,
        selectedPaymentMethod: selectedPaymentMethod,
        submissionErrorsAreVisible: submissionErrorsAreVisible,
        updateCheckoutErrorsAreVisible: updateCheckoutErrorsAreVisible,
        updateHasAgreedToPaymentPolicy: updateHasAgreedToPaymentPolicy,
        updateHasPaymentWaiver: updateHasPaymentWaiver,
        updateSelectedPaymentMethod: updateSelectedPaymentMethod,
        updateSubmissionErrorsAreVisible: updateSubmissionErrorsAreVisible,
        updatePaymentMethodErrors: updatePaymentMethodErrors,
        updatePaymentWaiverErrors: updatePaymentWaiverErrors,
      }}
    >
      {children}
    </CheckoutInfoContext.Provider>
  );
};

const useCheckoutInfo = () => {
  const {
    hasAgreedToPaymentPolicy,
    hasPaymentWaiver,
    selectedPaymentMethod,
    submissionErrorsAreVisible,
    updateCheckoutErrorsAreVisible,
    updateHasPaymentWaiver,
    updateHasAgreedToPaymentPolicy,
    updateSelectedPaymentMethod,
    updatePaymentMethodErrors,
    updatePaymentWaiverErrors,
    updateSubmissionErrorsAreVisible,
    checkoutFieldErrorStates,
    checkoutErrorsAreVisible,
  } = useContext<CheckoutInfo>(CheckoutInfoContext);

  const selectedPaymentMethodType: PaymentMethodType =
    selectedPaymentMethod?.paymentType ?? null;

  const handleHasAgreedToPaymentPolicyChange = (checked: boolean) => {
    updateHasAgreedToPaymentPolicy(checked);
  };

  const handleSelectedPaymentMethodChange = (
    paymentMethod: PaymentMethod | null
  ) => {
    if (paymentMethod !== selectedPaymentMethod) {
      updateSelectedPaymentMethod(paymentMethod);
      updatePaymentMethodErrors(!paymentMethod);
    }
  };

  function validatePaymentMethod() {
    return !!selectedPaymentMethod;
  }

  function validatePaymentWaiver() {
    if (hasPaymentWaiver) {
      updateHasAgreedToPaymentPolicy(hasAgreedToPaymentPolicy);
      return hasAgreedToPaymentPolicy;
    }

    return true;
  }

  function validateCheckout(): {
    methodIsValid: boolean;
    waiverIsValid: boolean;
  } {
    const methodIsValid = validatePaymentMethod();
    const waiverIsValid = validatePaymentWaiver();
    return { methodIsValid, waiverIsValid };
  }

  function updateErrorStates(
    displayMethodError: boolean,
    displayWaiverError: boolean
  ) {
    updatePaymentMethodErrors(displayMethodError);
    updatePaymentWaiverErrors(displayWaiverError);
  }

  return {
    validateCheckout: validateCheckout,
    updateErrorStates: updateErrorStates,
    checkoutFieldErrorStates: checkoutFieldErrorStates,
    checkoutErrorsAreVisible: checkoutErrorsAreVisible,
    hasAgreedToPaymentPolicy: hasAgreedToPaymentPolicy,
    hasPaymentWaiver: hasPaymentWaiver,
    selectedPaymentMethod: selectedPaymentMethod,
    selectedPaymentMethodType: selectedPaymentMethodType,
    submissionErrorsAreVisible: submissionErrorsAreVisible,
    updateCheckoutErrorsAreVisible: updateCheckoutErrorsAreVisible,
    updateHasAgreedToPaymentPolicy: updateHasAgreedToPaymentPolicy,
    updateHasPaymentWaiver: updateHasPaymentWaiver,
    handleHasAgreedToPaymentPolicyChange: handleHasAgreedToPaymentPolicyChange,
    updateSelectedPaymentMethod: handleSelectedPaymentMethodChange,
    updateSubmissionErrorsAreVisible: updateSubmissionErrorsAreVisible,
  };
};

export { CheckoutInfoContext, CheckoutInfoProvider, useCheckoutInfo };
