import { createSlice } from '@reduxjs/toolkit';
import { BillingSummaryData } from 'domains/Checkout/Checkout.types';
import { RootState } from './store';

interface State {
  summaryData: BillingSummaryData | null;
}

const initialState: State = {
  summaryData: null,
};

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setSummaryData(state, action) {
      state.summaryData = action.payload;
    },
  },
});

export const { setSummaryData } = checkoutSlice.actions;

export const getSummaryData = (state: RootState) => state.checkout.summaryData;

export default checkoutSlice.reducer;
