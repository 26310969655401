import { Button, DownloadIcon, PrintIcon } from '@la/ds-ui-components';
import { useTriggerCSVDownloadMutation } from 'redux/services/rosterManagementApi';
import * as S from './RosterManagement.styles';

export type RosterPageData = {
  programId?: string;
  programType?: string;
  teamId?: string;
};

export function formDataBodyFromValues(
  teamId: string,
  action: string
): FormData {
  const formDataBody = new FormData();
  formDataBody.append('teamId', teamId);
  formDataBody.append('action', action);
  return formDataBody;
}

/* Roster Utilities */
export default function RosterUtilities({
  programId,
  programType,
  teamId,
}: RosterPageData) {
  const [triggerCSVDownload] = useTriggerCSVDownloadMutation();

  function handleCSVDownloadButtonClick(): void {
    if (programId && programType && teamId) {
      const formDataBody = formDataBodyFromValues(teamId, 'ExportToCSV');

      triggerCSVDownload({
        body: formDataBody,
        programId: programId,
        programType: programType,
      });
    }
  }

  function handlePrinterRosterButtonClick(): void {
    window.open(
      `/printable?format=HTML&type=TEAM_ROSTER&issuedByRole=Captain&teamId=${teamId}&programId=${programId}`
    );
  }

  return (
    <S.RosterUtilities>
      <Button
        leftIcon={<DownloadIcon variant="bold" size="xl" />}
        onClick={handleCSVDownloadButtonClick}
        size="large"
        variant="text"
      >
        Download roster CSV
      </Button>
      <Button
        leftIcon={<PrintIcon variant="bold" size="xl" />}
        onClick={handlePrinterRosterButtonClick}
        size="large"
        variant="text"
      >
        Print roster
      </Button>
    </S.RosterUtilities>
  );
}
/* */
