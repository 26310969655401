import styled from 'styled-components';
import { ThemedComponent } from '@la/ds-ui-components';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 22px;
  margin: 20px 0;

  input[type='time'],
  input#date {
    margin: 4px 0;
  }
`;

export const FormButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;

  div:nth-child(1) button {
    padding-left: 0;
  }
`;

export const Flex = styled.div`
  display: flex;
  gap: 16px;

  > div {
    flex: 1;
  }
`;

export const FlexEnd = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 16px;

  > div {
    flex: 1;
  }
`;

export const RecurringDate = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  > div {
    flex: 1;
  }

  .repeatsFor {
    color: var(--blue-grey-600);
  }
`;

export const Label = styled.label<{ error?: boolean; disabled?: boolean }>`
  color: ${({ error, disabled }) => {
    if (error) {
      return 'var(--red-600)';
    } else if (disabled) {
      return 'var(--blue-grey-200)';
    }
    return 'var(--blue-grey-600)';
  }};
`;

export const BorderedFormSection = styled.div`
  border: 1px solid var(--blue-grey-400);
  border-radius: 6px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 22px;
`;

export const RecurringSnackbarMessage = styled.div``;

export const CancelSection = styled.div<ThemedComponent>(
  ({ theme }: ThemedComponent) => {
    const { red } = theme.foundational;

    return {
      backgroundColor: red[25],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: 60,
      padding: 12,
      '#cancel-button': {
        backgroundColor: red[600],
        borderColor: red[600],
      },
    };
  }
);

export const CancelText = styled.span<ThemedComponent>(
  ({ theme }: ThemedComponent) => {
    const { foundational, typography } = theme;

    return {
      ...typography.ui.medium,
      fontWeight: typography.weights.regular,
      color: foundational.blueGrey[900],
    };
  }
);

export const ActivityFormErrorMessage = styled.div`
  margin-top: 4px;
`;
