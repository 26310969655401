import styled from 'styled-components';

export const ActivityFormContainer = styled.div`
  margin: 50px auto;
  width: 100%;
  max-width: 800px;
  padding: 20px;

  > h2 {
    margin-bottom: 40px;
  }
`;
