import { forwardRef, useState } from 'react';
import { PlusCircleIcon, Typography } from '@la/ds-ui-components';
import { TeamPrimaryStaffSelectOptions } from '../utils/ActivityForm.utils';
import * as S from './ExternalTeamSelect.styles';

type ExternalTeamSelectProps = {
  onChange: (value?: string) => void;
  onClose: (value?: string) => void;
  selectedSubProgramId: string;
  teams: TeamPrimaryStaffSelectOptions[];
  value?: string;
};

export const ExternalTeamSelect = forwardRef<
  HTMLDivElement,
  ExternalTeamSelectProps
>(({ onChange, onClose, selectedSubProgramId, teams, value }, ref?) => {
  const [showCreateExternalTeam, setShowCreateExternalTeam] = useState(false);
  // const [selectedValue, setSelectedValue] = useState(value);

  const handleOnSelect = (team?: string) => {
    onChange(team);
    onClose(team);
  };

  // const handleOnBack = (teamId?: string) => {
  //   setShowCreateExternalTeam(false);
  //
  //   if (teamId) {
  //     setSelectedValue(teamId);
  //     onChange(teamId);
  //     onClose(teamId);
  //   }
  // };

  return (
    <S.TeamPrimaryStaffSelectWrapper>
      {!showCreateExternalTeam ? (
        <>
          <S.TeamOptionContainer
            role="option"
            aria-selected="false"
            onClick={() => setShowCreateExternalTeam(true)}
          >
            <S.ExternalTeamOption>
              <S.ExternalTeamOptionLink>
                <PlusCircleIcon fill="#007A00" variant="regular" /> Create a new
                external team
              </S.ExternalTeamOptionLink>
            </S.ExternalTeamOption>
          </S.TeamOptionContainer>

          {teams.map((team) => {
            return (
              <S.TeamOptionContainer
                role="option"
                aria-disabled={team.disabled}
                aria-selected={false}
                onClick={() => handleOnSelect(team.value)}
                key={team.value}
              >
                <S.ExternalTeamOption $isDisabled={team.disabled}>
                  <Typography variant="ui" size="large" weight="regular">
                    {team.label}
                  </Typography>
                  <Typography
                    variant="ui"
                    size="medium"
                    weight="regular"
                    italic
                  >
                    {team.primaryStaffName}
                  </Typography>
                </S.ExternalTeamOption>
              </S.TeamOptionContainer>
            );
          })}
        </>
      ) : (
        <></>
      )}
    </S.TeamPrimaryStaffSelectWrapper>
  );
});

// type CreateExternalTeamProps = {
//   onBack: (value?: string) => void;
//   selectedSubProgramId: string;
// };
