import { createActivity } from '../apis/createActivity';
import { createAllDayActivity } from '../apis/createAllDayActivity';
import { createRecurringActivity } from '../apis/createRecurringActivity';
import {
  ActivityRecurrenceFrequencyUnitEnum,
  ActivityTeam,
  AllDayActivityType,
} from '../openapi-client/models';
import {
  ActivityFormAllDayActivity,
  ActivityFormData,
  RecurringActivity,
  getAllDayActivityRequestBody,
  getCreateActivityRequestBody,
  processCreateRecurringActivitiesData,
} from './ActivityForm.utils';

export const createActivityHandler = ({
  data,
  siteId,
  programId,
}: {
  data: ActivityFormData;
  siteId: number;
  programId: number;
}) => {
  if (isRecurringActivity(data)) {
    const createRecurringActivityRequest = processCreateRecurringActivitiesData(
      data,
      programId,
      siteId
    );

    return createRecurringActivity({ createRecurringActivityRequest });
  } else {
    if (isAllDayActivityForm(data)) {
      const requestData = getAllDayActivityRequestBody(data);

      const teams: ActivityTeam[] = [
        {
          teamId: parseInt(data.team, 10),
          programId: parseInt(data.subProgram, 10),
          activityId: 0,
        },
      ];

      const createAllDayActivityRequestBody = {
        programId,
        siteId,
        teams: teams,
        type: data.activityType as AllDayActivityType,
        ...requestData,
      };

      return createAllDayActivity({ createAllDayActivityRequestBody });
    } else {
      const requestData = getCreateActivityRequestBody(data, siteId, programId);

      return createActivity({ createActivityRequestBody: requestData });
    }
  }
};

/* Type-guard for all day activity in form */
function isAllDayActivityForm(
  data: ActivityFormData
): data is ActivityFormAllDayActivity {
  return !!data.startDate && !!data.location && data.allDay;
}

/* Type-guard for recurring activity */
function isRecurringActivity(
  data: ActivityFormData
): data is RecurringActivity {
  return (
    data.frequency === ActivityRecurrenceFrequencyUnitEnum.Day ||
    data.frequency === ActivityRecurrenceFrequencyUnitEnum.Week ||
    data.frequency === ActivityRecurrenceFrequencyUnitEnum.Month
  );
}
