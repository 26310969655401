import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@la/ds-ui-components';
import { CheckoutCardHeader } from '../CheckoutCard/CheckoutCard';
import * as S from './TransactionConfirmationCard.styles';

export type TransactionConfirmationCardData = {
  cardBody: string[];
  cardTitle: string;
};

/* TransactionConfirmationCard */
export default function TransactionConfirmationCard({
  cardBody,
  cardTitle,
}: TransactionConfirmationCardData) {
  const navigate = useNavigate();

  function navigateToDashboard() {
    navigate('/dashboard');
  }

  const bodyText = cardBody.map((paragraph, idx) => (
    <Typography key={`trans-conf-${idx}`} size="large" variant="ui">
      {paragraph}
    </Typography>
  ));

  return (
    <S.TransactionConfirmationCard>
      <CheckoutCardHeader name={cardTitle} />
      <S.TransactionConfirmationCardContents>
        {bodyText}
        <Button size="medium" onClick={navigateToDashboard}>
          Visit dashboard
        </Button>
      </S.TransactionConfirmationCardContents>
    </S.TransactionConfirmationCard>
  );
}
/* */
