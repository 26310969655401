import styled from 'styled-components/macro';
import { Typography } from '@la/ds-ui-components';
import { Card } from 'components/Card/Card';
import { media } from 'lib/media-queries/mixins';

export const DetailsCard = styled(Card)`
  grid-column-start: 1;
  grid-column-end: -1;

  ${media.TabletLandscapeUp`
    grid-column-start: 12;
  `}
`;

export const LabeledValues = styled.ul`
  list-style-type: none;
  padding: 0;

  display: grid;
  gap: 12px;
`;

export const LabeledValue = styled.li`
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 12px;

  ${media.TabletPortraitUp`
    grid-template-columns: 1fr 3fr;
  `}

  ${media.TabletLandscapeUp`
    grid-template-columns: 2fr 3fr;
  `}

  ${media.DesktopUp`
    grid-template-columns: 3fr 7fr;
  `}
`;

export const Label = styled(Typography).attrs({
  variant: 'ui',
  size: 'large',
})`
  &:after {
    content: ':';
  }
`;

export const Value = styled(Typography).attrs({
  variant: 'ui',
  size: 'large',
  weight: 'medium',
})``;
