import styled from 'styled-components/macro';

export const PaymentMethodCardBody = styled.div<{
  $hasPaymentMethod: boolean;
}>`
  padding-bottom: ${({ $hasPaymentMethod }) =>
    $hasPaymentMethod ? '0px' : '16px'};
`;

export const SelectedPaymentMethod = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px 24px 24px;
`;

export const PaymentMethodDetail = styled.div`
  font: var(--ui-large-regular);
  color: var(--blue-grey-900);
`;

export const PaymentMethodLogo = styled.img`
  border: 1px solid var(--blue-grey-400);
  border-radius: 4px;
`;
