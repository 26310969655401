import baseApi from './baseApi';
import { TeamRegistration, Tournament } from './types/tournament';

export const tournamentApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTournament: builder.query<
      Tournament,
      {
        siteDomain: string;
        tournamentId: string;
      }
    >({
      query: ({ siteDomain, tournamentId }) => {
        return {
          url: `/api/member-portal/${siteDomain}/tournaments/${tournamentId}`,
          method: 'GET',
        };
      },
    }),
    getTournamentRegistration: builder.query<
      Tournament,
      {
        siteDomain: string;
        tournamentId: string;
      }
    >({
      query: ({ siteDomain, tournamentId }) => {
        return {
          url: `/api/member-portal/${siteDomain}/tournaments/${tournamentId}/registration`,
          method: 'GET',
        };
      },
      providesTags: ['Registration'],
    }),
    getTournamentRegistrant: builder.query<
      Tournament,
      {
        siteDomain: string;
        tournamentId: string;
      }
    >({
      query: ({ siteDomain, tournamentId }) => {
        return {
          url: `/api/member-portal/${siteDomain}/tournaments/${tournamentId}/registrant`,
          method: 'GET',
        };
      },
    }),
    getUserRegistrations: builder.query<
      { userRegistrations: TeamRegistration[] },
      {
        programId: string;
        siteDomain: string;
      }
    >({
      query: ({ programId, siteDomain }) => {
        return {
          url: `/api/member-portal/${siteDomain}/registration?programId=${programId}`,
          method: 'GET',
        };
      },
      providesTags: ['Registration'],
    }),
  }),
});

export const {
  useGetTournamentQuery,
  useGetTournamentRegistrationQuery,
  useGetUserRegistrationsQuery,
  useGetTournamentRegistrantQuery,
} = tournamentApi;
