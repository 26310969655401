import { Location, Program } from 'domains/ActivityForm/openapi-client/models';
import baseApi from './baseApi';

export const activityApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getProgram: builder.query<
      Program,
      {
        siteId: string | null;
        programId?: string;
      }
    >({
      query: ({ siteId, programId }) => {
        return {
          url: `/api/sites/${siteId}/programs/${programId}`,
          method: 'GET',
        };
      },
    }),
    getLocations: builder.query<
      Location[],
      {
        siteId: string | null;
        programId?: string;
      }
    >({
      query: ({ siteId, programId }) => {
        return {
          url: `/api/sites/${siteId}/locations?detectAssociationProgramId=${programId}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const { useGetProgramQuery, useGetLocationsQuery } = activityApi;
