import {
  DivisionCardStateWithId,
  DivisionCardsState,
} from 'domains/Tournaments/Registration/Wizard/Wizard.types';
import {
  SummaryAndSubmitProps,
  getDivisionCardsStateArray,
} from '../SummaryAndSubmit';
import { SummaryCards } from '../SummaryCards/SummaryCards';
import { WizardSubtotal } from '../WizardSubtotal/WizardSubtotal';
import * as S from './WizardSummary.styles';

export const REGISTRATION_WIZARD_SUMMARY_TEST_ID =
  'registration-wizard-summary-cards-and-subtotal';

/* WizardSummary */
export function WizardSummary(props: SummaryAndSubmitProps) {
  const { divisionCardsState, tournament } = props;

  const divisionsForSummarySection = getDivisionsToShowSummaryForLargerScreens(
    props.divisionCardsState
  );

  const shouldShowSubtotal = Object.values(divisionCardsState).some(
    ({ value }) => {
      return ['saved', 'saved-and-editing', 'not-saved'].includes(value);
    }
  );

  return (
    <S.WizardSummary data-testid={REGISTRATION_WIZARD_SUMMARY_TEST_ID}>
      <SummaryCards
        divisionsForSummarySection={divisionsForSummarySection}
        teams={props.teams}
        tournament={props.tournament}
      />
      {shouldShowSubtotal ? (
        <WizardSubtotal
          divisionCardsState={divisionCardsState}
          tournament={tournament}
        />
      ) : null}
    </S.WizardSummary>
  );
}
/* */

function getDivisionsToShowSummaryForLargerScreens(
  divisionCardsState: DivisionCardsState
): DivisionCardStateWithId[] {
  return getDivisionCardsStateArray(divisionCardsState).filter(
    (division) =>
      division.value === 'not-saved' ||
      division.value === 'saved' ||
      division.value === 'saved-and-editing'
  );
}
