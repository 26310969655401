import { PropsWithChildren, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { Button } from '@la/ds-ui-components';
import { useCheckoutInfo } from 'lib/context/CheckoutInfoContext';
import { addPlaidBankAccount } from 'lib/plaid/plaid';
import { getSiteId } from 'redux/coreSlice';
import { useGetPaymentMethodQuery } from 'redux/services/checkoutApi';
import { useGetUserIdQuery } from 'redux/services/userInfo';
import { useAppSelector } from 'redux/store';
import { ReactComponent as BankIcon } from 'assets/icons/icon_bank.svg';
import { ReactComponent as CreditCardIcon } from 'assets/icons/icon_payment_card.svg';
import {
  PaymentMethodInfo,
  SingleUseCard,
  StoredBankAccount,
  StoredCreditCard,
  StoredPaymentMethod,
} from '../Checkout.types';
import CheckoutCard, { CheckoutCardFooter } from '../CheckoutCard/CheckoutCard';
import { CHECKOUT_ERRORS } from '../CheckoutErrors';
import { isBankAccount } from '../PaymentMethodBadge/PaymentMethodBadge';
import {
  paymentMethodBadgeConfig,
  PaymentNetwork,
} from '../PaymentMethodBadge/PaymentMethodBadgeConfig';
import PaymentMethodModal from '../PaymentMethodModal/PaymentMethodModal';
import UseAccountModal from '../UseAccountModal/UseAccountModal';
import UseCardModal from '../UseCardModal/UseCardModal';
import * as S from './PaymentMethodCard.styles';

export type PaymentMethodBodyProps = {
  hasPaymentMethod: boolean;
};

export type ModalName = '' | 'useAccount' | 'useCard' | 'editPaymentMethod';

export enum BankAccountTypeFullName {
  'checking' = 'Checking Account',
  'savings' = 'Savings Account',
}

export type PaymentModalsProps = {
  closeModal: () => void;
  hasBackButton: boolean;
  modalName: ModalName;
  openCardModal: () => void;
  openEditPaymentModal: () => void;
  paymentMethodInfo?: PaymentMethodInfo;
};

/* PaymentMethodCard*/
export default function PaymentMethodCard() {
  const [searchParams] = useSearchParams();
  const isAccountConnected = searchParams.get('accountConnected') === 'true';
  const {
    checkoutErrorsAreVisible,
    checkoutFieldErrorStates,
    selectedPaymentMethod,
    updateSelectedPaymentMethod,
  } = useCheckoutInfo();
  const siteId = useAppSelector(getSiteId);
  const { data: userId } = useGetUserIdQuery(siteId);
  const skip = !userId || !siteId;
  const { data: storedPaymentMethodsData /*isLoading,error */ } =
    useGetPaymentMethodQuery(
      {
        siteId,
        userId,
      },
      { skip }
    );
  const hasPaymentMethod = !!selectedPaymentMethod;
  const [openModalName, setOpenModalName] = useState<ModalName>('');

  function openEditPaymentModal() {
    setOpenModalName('editPaymentMethod');
  }

  function openCardModal() {
    setOpenModalName('useCard');
  }

  function closeModal() {
    setOpenModalName('');
  }

  /**
   * This useEffect is used to determine what the current selected payment
   * method should be. If a temporary credit card has been added it will be
   * that, otherwise it should be selected from the stored payment methods.
   *
   * - if there is already a value for selectedPaymentMethod in
   * CheckoutInfoContext or `storedPaymentMethodsData` is `null`
   * -- exit the useEffect with `return`
   * - storedPaymentMethodsData is retrieved via useGetPaymentMethodQuery
   * - the stored payment methods are aggregated into a single array
   * - the first stored payment method that has `isPrimaryPaymentOption` set to
   * true is set as the value of `primaryPaymentMethod` (yes, more than one
   * can be set in the DB with `isPrimaryPaymentOption: true`)
   * - if none of the stored payment methods has `isPrimaryPaymentOption: true`
   * -- then the first stored payment method in the array is set as the
   * value of `primaryPaymentOption`
   * - if the isAccountConnected query param is true, then use the first stored bank account, if one exists
   * - if there are no stored payment methods
   * -- then primaryPaymentMethod will be `null`
   * - update selectedPaymentMethod in CheckoutInfoContext with
   * `primaryPaymentMethod`
   */
  useEffect(() => {
    if (selectedPaymentMethod || !storedPaymentMethodsData) {
      return;
    }

    const storedBankAccounts =
      storedPaymentMethodsData?.storedBankAccounts ?? [];
    const storedCreditCards = storedPaymentMethodsData?.storedCreditCards ?? [];
    const paymentMethods: StoredPaymentMethod[] = [
      ...storedCreditCards,
      ...storedBankAccounts,
    ];
    let primaryPaymentMethod =
      paymentMethods.find(
        (paymentMethod) => paymentMethod.isPrimaryPaymentOption
      ) ??
      paymentMethods[0] ??
      null;
    if (isAccountConnected && storedBankAccounts[0]) {
      primaryPaymentMethod = storedBankAccounts[0];
    }

    updateSelectedPaymentMethod(primaryPaymentMethod);
  }, [
    isAccountConnected,
    storedPaymentMethodsData,
    selectedPaymentMethod,
    updateSelectedPaymentMethod,
  ]);

  const isShowingErrorMessage =
    checkoutFieldErrorStates.hasPaymentMethodError && checkoutErrorsAreVisible;

  return (
    <>
      <CheckoutCard
        cardName="Payment method"
        errorMessage={
          isShowingErrorMessage ? CHECKOUT_ERRORS.paymentMethod : ''
        }
      >
        <PaymentMethodCardBody hasPaymentMethod={hasPaymentMethod}>
          <SelectedPaymentMethodDetail />
        </PaymentMethodCardBody>
        <CheckoutCardFooter>
          {hasPaymentMethod ? (
            <Button
              variant="outline"
              size="medium"
              onClick={() => setOpenModalName('editPaymentMethod')}
            >
              Edit payment method
            </Button>
          ) : (
            <>
              <Button
                leftIcon={<BankIcon />}
                size="medium"
                onClick={() => addPlaidBankAccount(userId, siteId)}
              >
                Add bank account
              </Button>
              <Button
                leftIcon={<CreditCardIcon />}
                size="medium"
                onClick={() => setOpenModalName('useCard')}
              >
                Add card
              </Button>
            </>
          )}
        </CheckoutCardFooter>
      </CheckoutCard>
      <PaymentModals
        closeModal={closeModal}
        hasBackButton={hasPaymentMethod}
        modalName={openModalName}
        openEditPaymentModal={openEditPaymentModal}
        openCardModal={openCardModal}
        paymentMethodInfo={storedPaymentMethodsData}
      />
    </>
  );
}
/* */

/*PaymentMethodCardBody */
function PaymentMethodCardBody({
  hasPaymentMethod,
  children,
}: PropsWithChildren<PaymentMethodBodyProps>) {
  return (
    <S.PaymentMethodCardBody $hasPaymentMethod={hasPaymentMethod}>
      {children}
    </S.PaymentMethodCardBody>
  );
}
/* */

/*SelectedPaymentMethodDetail*/
/**
 * If there's a selectedPaymentMethod set in CheckoutInfoContext,
 *  and it's a bank account
 *    return <BankDetail />,
 *  otherwise it's a credit card, so
 *    return <PaymentCardDetail />,
 * otherwise there's no selectedPaymentMethod set, so
 *  return null
 * @returns {JSX.Element | null}
 * @constructor
 */
function SelectedPaymentMethodDetail() {
  const { selectedPaymentMethod } = useCheckoutInfo();
  if (selectedPaymentMethod) {
    if (isBankAccount(selectedPaymentMethod)) {
      return <BankDetail selectedPaymentMethod={selectedPaymentMethod} />;
    }

    return <PaymentCardDetail selectedPaymentMethod={selectedPaymentMethod} />;
  }

  return null;
}
/* */

/*BankDetail */
function BankDetail({
  selectedPaymentMethod,
}: {
  selectedPaymentMethod: StoredBankAccount;
}) {
  const { bankName, last4Digits, accountType } = selectedPaymentMethod;
  const { logo, name } = paymentMethodBadgeConfig.bank;
  return (
    <S.SelectedPaymentMethod>
      <S.PaymentMethodLogo src={logo} alt={name} />
      <S.PaymentMethodDetail>
        {bankName} ****{last4Digits} |{' '}
        {
          BankAccountTypeFullName[
            accountType as keyof typeof BankAccountTypeFullName
          ]
        }
      </S.PaymentMethodDetail>
    </S.SelectedPaymentMethod>
  );
}
/* */

function extractCardDetails(paymentMethod: SingleUseCard | StoredCreditCard): {
  expDate: string;
  cardLast4: string;
  network: PaymentNetwork;
} {
  let expDate, cardLast4, network;
  if (paymentMethod.cardType === 'STORED') {
    expDate = DateTime.fromISO(paymentMethod.expirationDate).toFormat('MM/yy');
    cardLast4 = paymentMethod.last4Digits;
    network = paymentMethod.paymentNetwork;
  } else {
    if (
      !paymentMethod.cardExpirationMonth ||
      !paymentMethod.cardExpirationYear
    ) {
      expDate = '00/00';
    } else {
      expDate = `${
        paymentMethod.cardExpirationMonth
      }/${paymentMethod.cardExpirationYear?.toString().substring(2)}`;
    }
    cardLast4 = paymentMethod.cardLast4;
    network = paymentMethod.cardBrand as PaymentNetwork;
  }
  return { expDate, cardLast4, network };
}

/*PaymentCardDetail */
function PaymentCardDetail({
  selectedPaymentMethod,
}: {
  selectedPaymentMethod: SingleUseCard | StoredCreditCard;
}) {
  const { expDate, cardLast4, network } = extractCardDetails(
    selectedPaymentMethod
  );
  const creditCardDetails = paymentMethodBadgeConfig.creditCard;
  const { logo, name } = creditCardDetails[network];

  return (
    <S.SelectedPaymentMethod>
      <S.PaymentMethodLogo src={logo} alt={name} />
      <S.PaymentMethodDetail>
        {network} ****{cardLast4} | {expDate}
      </S.PaymentMethodDetail>
    </S.SelectedPaymentMethod>
  );
}
/* */

/* PaymentModals */
function PaymentModals({
  closeModal,
  hasBackButton,
  modalName,
  openCardModal,
  openEditPaymentModal,
  paymentMethodInfo,
}: PaymentModalsProps) {
  switch (modalName) {
    case 'useCard':
      return (
        <UseCardModal
          closeModal={closeModal}
          hasBackButton={hasBackButton}
          modalTitle="Payment method"
          openEditPaymentModal={openEditPaymentModal}
        />
      );
    case 'useAccount':
      return (
        <UseAccountModal closeModal={closeModal} modalTitle="Payment method" />
      );
    case 'editPaymentMethod':
      return paymentMethodInfo ? (
        <PaymentMethodModal
          closeModal={closeModal}
          modalTitle="Edit payment method"
          openCardModal={openCardModal}
          paymentMethodData={paymentMethodInfo}
        />
      ) : null;
    default:
      return null;
  }
}
