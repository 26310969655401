import { DateTime } from 'luxon';
import { Button, Modal } from '@la/ds-ui-components';
import { User } from 'lib/apis/createPlayer';
import * as S from './RegistrationInfoCard.styles';

export type PlayerDetailsViewModalProps = {
  /**
   * Called when the `open` state of the modal changes.
   * @param open Whether or not the modal is open.
   */
  onOpenChange: (open: boolean) => void;
  /**
   * True if the modal should be open, false otherwise.
   */
  open?: boolean;
  // TODO: Switch back to PlayerDetailsFormFields once we can retrieve
  // the address details for a player.
  /**
   * Details about player to display in the modal.
   */
  player: User;
};

type PlayerDetail = { label: string; value?: string };
const PlayerDetails = ({ details }: { details: PlayerDetail[] }) => {
  return (
    <>
      {details.map((detail, index) => (
        <S.PlayerDetail key={`player-detail-${index}`}>
          <S.PlayerDetailLabel size="large" variant="ui">
            {detail.label}:{' '}
          </S.PlayerDetailLabel>
          <S.PlayerDetailValue size="large" variant="ui" weight="bold">
            {detail.value}
          </S.PlayerDetailValue>
        </S.PlayerDetail>
      ))}
    </>
  );
};

const PlayerDetailsViewModal = ({
  onOpenChange,
  open,
  player,
}: PlayerDetailsViewModalProps) => {
  const {
    firstName,
    lastName,
    gender,
    birthdate,
    // TODO: Uncomment once we can retrieve the address details
    // for a player.
    // address1,
    // city,
    // state,
    // zipCode,
  } = player;
  const name = `${firstName} ${lastName}`;
  // TODO: Uncomment once we can retrieve the address details for a player.
  // const addressDetails = `${city}, ${state} ${zipCode}`;

  const details: PlayerDetail[] = [{ label: 'Player name', value: name }];
  details.push();

  // optional fields
  if (gender) {
    details.push({ label: 'Gender', value: gender });
  }
  if (birthdate) {
    details.push({
      label: 'Birth date',
      value: DateTime.fromISO(birthdate).toFormat('MMMM dd, yyyy'),
    });
  }

  // TODO: Uncomment once we can retrieve the address details for a user.
  // details.push(
  //   { label: 'Street address', value: address1 },
  //   { label: 'City, State, and Zip code', value: addressDetails }
  // );

  return (
    <Modal
      onOpenChange={onOpenChange}
      open={open}
      primaryAction={<Button onClick={() => onOpenChange(false)}>Ok</Button>}
      title="Player details"
    >
      <PlayerDetails details={details} />
    </Modal>
  );
};

export { PlayerDetailsViewModal };
