import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ColumnGrid from 'components/ColumnGrid/ColumnGrid';
import useMediaQuery from 'lib/hooks/useMediaQuery';
import { breakpointQueries } from 'lib/media-queries/breakpoints';
import { getLAHostnameParts } from 'lib/utils/urlUtils';
import { getSiteIdentityData } from 'redux/coreSlice';
import {
  useGetInvitesQuery,
  useGetTeamRosterQuery,
} from 'redux/services/rosterManagementApi';
import { useAppSelector } from 'redux/store';
import PageTitle from 'domains/Shell/PageTitle/PageTitle';
import InvitePlayerStaffModals from './InvitePlayerStaffModals';
import RosterSection from './RosterSection';
import RosterSideNav from './RosterSideNav';
import RosterUtilities from './RosterUtilities';
import * as S from './RosterManagement.styles';

export type StaffInvitation = {
  link: string;
  role: string;
};
export type InvitationLinks = {
  playerInviteLink: string;
  staffInviteLinks: StaffInvitation[];
};

/* Roster Management Page */
export default function RosterManagement() {
  const { siteName } = useAppSelector(getSiteIdentityData);
  const { programId, programType, teamId } = useParams();
  const siteSubdomain = getLAHostnameParts().subdomain;
  const { data /* error, isError, isLoading */ } = useGetTeamRosterQuery(
    {
      siteSubdomain,
      teamId,
    },
    { skip: !teamId }
  );
  const { data: invitesData, isLoading: invitesDataIsLoading } =
    useGetInvitesQuery(
      {
        programId,
        siteSubdomain,
        teamId,
      },
      { skip: !programId }
    );
  const [openModal, setOpenModal] = useState<string | null>(null);

  const { phoneOnly, tabletPortraitUp, tabletLandscapeUp } = breakpointQueries;
  const isPhone = useMediaQuery(phoneOnly);
  const isTabletPortrait = useMediaQuery(tabletPortraitUp);
  const isTabletLandscape = useMediaQuery(tabletLandscapeUp);

  let teamNameSize: 'large' /* | 'large-mobile' */ = 'large',
    tableTitleSize: 'medium' /* | 'medium-mobile' */ = 'medium';

  if (isPhone) {
    teamNameSize = 'large'; /* 'large-mobile' once this size is added */
    tableTitleSize = 'medium' /* 'medium-mobile' once this size is added */;
  } else if (isTabletPortrait) {
    teamNameSize = 'large';
  } else if (isTabletLandscape) {
    teamNameSize = 'large'; /* 'large-mobile' once this size is added */
  }

  useEffect(() => {
    document.title = siteName
      ? `${siteName}: Roster Management`
      : 'Roster Management';
  }, [siteName]);

  return (
    <S.RosterManagementPage>
      <PageTitle>Manage your team roster</PageTitle>
      <ColumnGrid>
        <S.MinorColumn>
          <RosterSideNav
            programId={programId}
            programType={programType}
            teamId={teamId}
            teamNameSize={teamNameSize}
          />
        </S.MinorColumn>
        <S.MajorColumn>
          <RosterUtilities
            programId={programId}
            programType={programType}
            teamId={teamId}
          />
          {data?.players ? (
            <RosterSection
              openModal={() => setOpenModal('invitePlayers')}
              rosterData={data.players}
              rosterTitle="Players"
              rosterType="players"
              tableTitleSize={tableTitleSize}
            />
          ) : null}
          {data?.staff ? (
            <RosterSection
              openModal={() => setOpenModal('inviteStaff')}
              rosterData={data?.staff}
              rosterTitle="Staff"
              rosterType="staff"
              tableTitleSize={tableTitleSize}
            />
          ) : null}
        </S.MajorColumn>
      </ColumnGrid>
      {!invitesDataIsLoading ? (
        <InvitePlayerStaffModals
          closeModalMethod={() => setOpenModal(null)}
          invitesData={invitesData}
          openModalName={openModal}
        />
      ) : null}
    </S.RosterManagementPage>
  );
}
/* */
