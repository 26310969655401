import {
  ChangeEvent,
  forwardRef,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
  Ref,
} from 'react';
import * as S from './TextField.styles';

type WithLabelProps = {
  label: string;
  'aria-labelledby'?: never;
};

type WithoutLabelProps = {
  label?: never;
  'aria-labelledby': string;
};

type LabelProps = WithLabelProps | WithoutLabelProps;

export type InputTypeProps =
  | {
      multiline?: false;
      inputRef?: Ref<HTMLInputElement>;
    }
  | {
      multiline: true;
      inputRef?: Ref<HTMLTextAreaElement>;
    };

export type TextFieldProps = {
  $iconPosition?: 'start' | 'end';
  cols?: number;
  cursor?: string;
  disabled?: boolean;
  error?: boolean;
  fullWidth?: boolean;
  helperText?: string;
  icon?: ReactNode;
  id: string;
  ref?: Ref<HTMLDivElement>;
  maxLength?: number;
  name: string;
  onKeyDown?: (
    event: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  onChange?: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  onIconClick?: (event: MouseEvent<HTMLSpanElement>) => void;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  rows?: number;
  size?: S.TextInputSize;
  type?: 'text' | 'email' | 'password' | 'number' | 'time';
  value?: string;
} & LabelProps &
  InputTypeProps;

export type TextInputProps = Omit<
  TextFieldProps,
  'cols' | 'inputRef' | 'multiline' | 'ref' | 'rows'
>;

export type TextAreaProps = Omit<
  TextFieldProps,
  | 'icon'
  | 'iconPosition'
  | 'inputRef'
  | 'multiline'
  | 'onIconClick'
  | 'ref'
  | 'size'
  | 'type'
>;

/* TextField */
export const TextField = (props: TextFieldProps) => {
  const {
    error,
    fullWidth,
    helperText,
    id,
    label,
    multiline,
    onClick,
    ref,
    required,
  } = props;

  return (
    <S.TextFieldWrapper $fullWidth={fullWidth} onClick={onClick} ref={ref}>
      {label ? (
        <S.TextFieldLabel
          htmlFor={id}
          id={`${id}-label`}
          $error={error}
          $required={required}
        >
          {label}
        </S.TextFieldLabel>
      ) : null}
      {!multiline ? (
        <TextInput
          {...props}
          ref={!props.multiline ? props.inputRef : undefined}
        />
      ) : (
        <TextArea
          {...props}
          ref={props.multiline ? props.inputRef : undefined}
        />
      )}
      {helperText ? (
        <S.TextFieldHelperText $error={error}>
          {helperText}
        </S.TextFieldHelperText>
      ) : null}
    </S.TextFieldWrapper>
  );
};

/* */

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      'aria-labelledby': ariaLabelledby,
      error,
      fullWidth,
      icon,
      $iconPosition = 'end',
      id,
      label,
      onIconClick,
      size = 'large',
      type = 'text',
      ...inputProps
    },
    ref?
  ) => {
    return (
      <>
        <S.TextInput
          $size={size}
          aria-labelledby={!!label ? `${id}-label` : ariaLabelledby}
          $error={error}
          $fullWidth={fullWidth}
          $iconPosition={$iconPosition}
          id={id}
          ref={ref}
          type={type}
          {...inputProps}
        />
        {icon ? (
          <TextFieldIconContainer
            $iconPosition={$iconPosition}
            onIconClick={onIconClick}
            size={size}
          >
            {icon}
          </TextFieldIconContainer>
        ) : null}
      </>
    );
  }
);

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      'aria-labelledby': ariaLabelledby,
      cols,
      disabled,
      error,
      fullWidth,
      id,
      label,
      maxLength,
      name,
      onChange,
      placeholder,
      readOnly,
      required,
      rows,
      value,
    },
    ref?
  ) => {
    return (
      <S.TextArea
        aria-labelledby={!!label ? `${id}-label` : ariaLabelledby}
        cols={cols}
        disabled={disabled}
        $error={error}
        $fullWidth={fullWidth}
        id={id}
        maxLength={maxLength}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        readOnly={readOnly}
        ref={ref}
        required={required}
        rows={rows}
        value={value}
      />
    );
  }
);

export function TextFieldIconContainer({
  $disabled,
  $hasError,
  $iconPosition,
  children,
  onIconClick,
  size,
}: {
  $disabled?: boolean;
  $hasError?: boolean;
  $iconPosition: 'start' | 'end';
  children: ReactNode;
  onIconClick?: (event: MouseEvent<HTMLSpanElement>) => void;
  size?: S.TextInputSize;
}) {
  function handleIconClick(evt: MouseEvent<HTMLElement>) {
    if (onIconClick) {
      onIconClick(evt);
    }
  }

  return (
    <S.TextFieldIconContainer
      $disabled={$disabled}
      $hasError={$hasError}
      $iconPosition={$iconPosition}
      $size={size}
      onClick={handleIconClick}
      role={onIconClick ? 'button' : undefined}
    >
      {children}
    </S.TextFieldIconContainer>
  );
}

export default TextField;
