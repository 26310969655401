import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import {
  Button,
  Checkbox,
  InputErrorMessage,
  Select,
  SelectOption,
  TextArea,
  TextInput,
  Typography,
} from '@la/ds-ui-components';
import { DatePicker } from 'components/DatePicker/DatePicker';
import { SelectPopover } from 'components/SelectPopover/SelectPopover';
import TextField from 'components/TextField/TextField';
import { getLAHostnameParts } from 'lib/utils/urlUtils';
import { getSiteIdentityData } from 'redux/coreSlice';
import {
  useGetLocationsQuery,
  useGetProgramQuery,
} from 'redux/services/activity';
import { useGetStaffTeamsQuery } from 'redux/services/teamApi';
import { useAppSelector } from 'redux/store';
import { getUserId, getUserName } from 'redux/userSlice';
import { ActivityFormConfirmation } from './ActivityFormConfirmation/ActivityFormConfirmation';
// import { useGetSiteSettingsQuery } from 'redux/services/siteInfo';
import { ExternalTeamSelect } from './ExternalTeamSelect/ExternalTeamSelect';
import { TeamPrimaryStaffSelect } from './TeamPrimaryStaffSelect/TeamPrimaryStaffSelect';
import {
  Activity,
  ActivityRecurrenceFrequencyUnitEnum,
  ActivityState,
  ActivityType,
  AllDayActivityType,
} from './openapi-client/models';
import {
  ActivityFormData,
  gameTypeOptions,
  creativeActivitySchema,
  durationBetweenTwoDates,
  formHasBeenChanged,
  formatDataForExternalTeams,
  formatDataForHomeAwayTeamSelect,
  frequencyOptions,
  getSubLocationsByLocationId,
  formatDataForActivityTypeSelect,
  getHomeAwayTeamsId,
  TeamWithProps,
} from './utils/ActivityForm.utils';
import { createActivityHandler } from './utils/ActivityFormSubmit.utils';
import { formatDataForSelectOption } from './utils/select';
import { convertDateTimeToISO, formatISODateTime } from './utils/timeUtils';
import * as S from './ActivityForm.styles';

export type RecurringActivityType = 'all' | 'one';

export type ActivityFormProps = {
  editActivity?: Activity;
  editRecurringType?: RecurringActivityType;
  formId: string;
  teamId?: string;
  subProgramId?: string;
  originRoute: string;
};

type ExternalTeamType = 'home' | 'away' | undefined;

/* ActivityForm */
export function ActivityForm({
  editActivity,
  editRecurringType,
  formId,
  originRoute,
  subProgramId,
  teamId,
}: ActivityFormProps) {
  const {
    control,
    formState: { errors, isSubmitted, isSubmitting },
    handleSubmit,
    register,
    resetField,
    setValue,
    trigger,
    watch,
  } = useForm<ActivityFormData>({
    defaultValues: {
      activityType: editActivity?.type,
      allDay: editActivity?.allDay,
      arrival: editActivity?.arrival?.time,
      endDate: editActivity?.end?.date,
      endTime: editActivity?.end?.time,
      frequency: 'does_not_repeat',
      hasSubLocations: editActivity?.subLocationId !== null,
      location: editActivity?.locationId?.toString(),
      notes: editActivity?.notes,
      startDate: editActivity?.start?.date,
      startTime: editActivity?.start?.time,
      subLocation: editActivity?.subLocationId?.toString(),
      subProgram: editActivity
        ? editActivity?.teams[0].programId.toString()
        : subProgramId,
      team: editActivity
        ? getHomeAwayTeamsId(editActivity.teams).homeTeam.toString()
        : teamId?.toString(),
      title: editActivity?.title,
      gameType: editActivity?.type,
      awayTeam: editActivity
        ? getHomeAwayTeamsId(editActivity.teams).awayTeam?.toString()
        : undefined,
    },
    mode: 'onSubmit',
    resolver: yupResolver(creativeActivitySchema),
  });

  const [submitted, setSubmitted] = useState<{
    activity?: Activity[];
    errorMessage?: string;
  }>();
  const isEditForm = !!editActivity;

  const userId = useAppSelector(getUserId);
  const { data: teams = [] } = useGetStaffTeamsQuery(
    { userId },
    { skip: !userId ? true : false }
  );

  const { siteId } = useAppSelector(getSiteIdentityData);
  const userName = useAppSelector(getUserName);
  //TODO: Add back when BE is fixed
  // const { data: siteSettings } = useGetSiteSettingsQuery(Number(siteId));

  /**
   * If editing a recurring activity, we need to show the
   * warning snackbar once the form is rendered.
   * eslint complains about missing dependency 'showEditRecurringActivityWarning'
   * but adding it causes an infinite loop
   */
  useEffect(() => {
    // let message = '';
    if (editRecurringType === 'one') {
      // message = `You're editing one instance in a set of recurring activities. The other activities in this set won’t be affected.`;
    } else if (editRecurringType === 'all' && editActivity?.seqToken) {
      // const numberOfRecurringActivities = getSubsequentActivitiesBySeqToken({
      //   id: editActivity.id,
      //   seqToken: editActivity.seqToken,
      // }).length;
      // const activityPluralSingular =
      //   numberOfRecurringActivities === 1 ? 'activity' : 'activities';
      // message = `You're editing ${numberOfRecurringActivities} ${activityPluralSingular} in a set of recurring activities.`;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editRecurringType]);

  /* Selected fields */
  const frequency = watch('frequency');
  const activityType = watch('activityType');
  const [selectedLocationId, setSelectedLocationId] = useState<string>('');
  const [selectedStartDate, setSelectedStartDate] = useState('');
  const [selectedEndDate, setSelectedEndDate] = useState('');
  const [selectedRecurringEndDate, setSelectedRecurringEndDate] = useState('');
  const [selectedSubProgramId, setSelectedSubProgramId] = useState<string>('');
  const [allDayActivity, setAllDayActivity] = useState<boolean>(
    !!editActivity?.allDay
  );
  const isActivityTypeGame = isGameActivity(activityType);

  /** Team Select Options */
  const [selectedTeam, setSelectedTeam] = useState<SelectOption | undefined>();
  const [isTeamSelectOpen, setIsTeamSelectOpen] = useState(false);
  const [showHomeTeamExternalTeamLabel, setShowHomeTeamExternalTeamLabel] =
    useState<boolean>(isActivityTypeGame);
  const [externalTeamNameLabel, setExternalTeamNameLabel] =
    useState<ExternalTeamType>();
  const [selectedExternalTeam, setSelectedExternalTeam] = useState<
    SelectOption | undefined
  >();
  const [
    isExternalHomeTeamNameSelectOpen,
    setIsExternalHomeTeamNameSelectOpen,
  ] = useState(false);
  const [selectedAwayTeam, setSelectedAwayTeam] = useState<
    SelectOption | undefined
  >();
  const [isAwayTeamSelectOpen, setIsAwayTeamSelectOpen] = useState(false);
  const [showAwayTeamExternalTeamLabel, setShowAwayTeamExternalTeamLabel] =
    useState<boolean>(isActivityTypeGame);
  const [
    isExternalAwayTeamNameSelectOpen,
    setIsExternalAwayTeamNameSelectOpen,
  ] = useState(false);
  // const externalTeamsEnabled = siteSettings?.externalTeamsEnabled;
  const externalTeamsEnabled = false;
  const showHomeExternalTeamOption =
    externalTeamsEnabled &&
    showHomeTeamExternalTeamLabel &&
    externalTeamNameLabel !== 'away';
  const showAwayExternalTeamOption =
    externalTeamsEnabled &&
    showAwayTeamExternalTeamLabel &&
    externalTeamNameLabel !== 'home';
  /** */

  useEffect(() => {
    const selectedTeam = teams.find(({ id }) => {
      return id === Number(teamId);
    });
    if (selectedTeam && teamId) {
      setSelectedTeam({ label: selectedTeam.name, value: teamId });
    }
  }, [teams, teamId]);

  /* Selectors */
  // const { getAllSubPrograms } = useSubPrograms();
  // const {
  //   getAllTeams,
  //   getTeamsBySubProgramId,
  //   getTeamNameById,
  //   isTeamExternal,
  // } = useTeams();
  // const subPrograms = getAllSubPrograms();
  //
  // const teams = selectedSubProgramId
  //   ? getTeamsBySubProgramId(parseInt(selectedSubProgramId, 10))
  //   : getAllTeams();

  const activityTypeOptions = useMemo(
    () => formatDataForActivityTypeSelect(activityType, isActivityTypeGame),
    [activityType, isActivityTypeGame]
  );

  const homeTeamOptions = useMemo(() => {
    // const teams: any[] = [];
    const awayTeam =
      selectedAwayTeam?.value === 'external'
        ? undefined
        : selectedAwayTeam?.value;

    return formatDataForHomeAwayTeamSelect({
      teams,
      isGame: isActivityTypeGame,
      selectedSubProgramId,
      selectedHomeAwayTeamId: awayTeam,
      username: userName,
    });
  }, [
    isActivityTypeGame,
    selectedAwayTeam?.value,
    selectedSubProgramId,
    teams,
    userName,
  ]);

  const awayTeamOptions = useMemo(() => {
    const homeTeam =
      selectedTeam?.value === 'external' ? undefined : selectedTeam?.value;

    return formatDataForHomeAwayTeamSelect({
      teams,
      isGame: isActivityTypeGame,
      selectedSubProgramId,
      selectedHomeAwayTeamId: homeTeam,
      username: userName,
    });
  }, [
    isActivityTypeGame,
    selectedSubProgramId,
    selectedTeam?.value,
    teams,
    userName,
  ]);

  const externalTeamOptions = useMemo(() => {
    const teams: any[] = [];
    return formatDataForExternalTeams({
      teams,
      selectedSubProgramId,
    });
  }, [selectedSubProgramId]);

  useEffect(() => {
    setShowHomeTeamExternalTeamLabel(isActivityTypeGame);
    setShowAwayTeamExternalTeamLabel(isActivityTypeGame);

    if (!isActivityTypeGame) {
      //reset dependent fields
      if (selectedTeam?.value === 'external') {
        setSelectedTeam(undefined);
        resetField('team', { defaultValue: '', keepError: true });
        resetField('externalTeam', { defaultValue: '', keepError: true });
      } else if (selectedAwayTeam?.value === 'external') {
        resetField('awayTeam', { defaultValue: '', keepError: true });
        setSelectedAwayTeam(undefined);
      }

      if (
        selectedTeam?.value === 'external' ||
        selectedAwayTeam?.value === 'external'
      ) {
        setSelectedExternalTeam(undefined);
        setExternalTeamNameLabel(undefined);
      }
    }
  }, [
    isActivityTypeGame,
    resetField,
    selectedAwayTeam?.value,
    selectedTeam?.value,
  ]);

  /* Select Options */
  // const subProgramOptions = formatDataForSelectOption([], 'name', 'id');
  const { data: program } = useGetProgramQuery(
    {
      siteId: siteId,
      programId: subProgramId,
    },
    { skip: !subProgramId ? true : false }
  );

  const { data: allLocations } = useGetLocationsQuery(
    {
      siteId: siteId,
      programId: program ? program?.masterProgramId?.toString() : '',
    },
    { skip: !program || !siteId ? true : false }
  );

  const locations =
    allLocations?.filter((location) => location.associated) ?? [];
  const locationsOptions = formatDataForSelectOption(locations, 'name', 'id');
  const subLocationOptions = getSubLocationsByLocationId(
    locations,
    selectedLocationId
  );

  useEffect(() => {
    setValue('hasSubLocations', subLocationOptions.length > 0);
  }, [setValue, subLocationOptions]);

  /**
   * If there's activity data, populate the form helpers with that data.
   */
  useEffect(() => {
    if (isEditForm) {
      const { end, locationId, start, teams } = editActivity;
      const currentTeam = teams[0];
      const currentLocationId = locationId ? locationId.toString() : '';
      const currentSubProgramId = currentTeam.programId
        ? currentTeam.programId.toString()
        : subProgramId;

      setSelectedLocationId(currentLocationId);
      if (currentSubProgramId) {
        setSelectedSubProgramId(currentSubProgramId);
      }
      setSelectedStartDate(start?.date ?? '');
      setSelectedEndDate(end?.date ?? '');
    }
  }, [subProgramId, editActivity, isEditForm]);

  /**
   * set Team SelectPopover default value for edit form
   * do not update if team, external team, or subprogram is changed
   */

  /* Change Event Handlers */
  // function handleSubProgramChange(newSubProgramId: string) {
  //   setSelectedSubProgramId(newSubProgramId);
  //
  //   //reset dependent fields
  //   setSelectedTeam(undefined);
  //   setSelectedAwayTeam(undefined);
  //   setSelectedExternalTeam(undefined);
  //   setExternalTeamNameLabel(undefined);
  //   resetField('team', { defaultValue: '', keepError: true });
  //   resetField('awayTeam', { defaultValue: '', keepError: true });
  //   resetField('externalTeam', { defaultValue: '', keepError: true });
  // }

  function handleLocationChange(newLocationId: string) {
    setSelectedLocationId(newLocationId);
    resetField('subLocation', { defaultValue: '', keepError: true });
  }

  function handleAllDayChange(allDay: boolean) {
    setAllDayActivity(allDay);

    resetField('startTime', { keepError: false });
    resetField('endTime', { keepError: false });
    resetField('frequency', { keepError: false });
    setSelectedRecurringEndDate('');

    if (allDay && selectedEndDate === '') {
      setSelectedEndDate(selectedStartDate);
      setValue('endDate', selectedStartDate);
    } else {
      setSelectedEndDate('');
      resetField('endDate', { keepError: false });
    }
  }

  function handleStartDateSelected(date: string) {
    setSelectedStartDate(date);

    if (selectedEndDate === '' && allDayActivity) {
      setSelectedEndDate(date);
      setValue('endDate', date);
    }
    //reset error message if exists
    if (isSubmitted) {
      trigger('endDate').then();
    }
  }

  function handleAllDayEndDateSelected(date: string) {
    setSelectedEndDate(date);

    //reset error message if exists
    if (isSubmitted) {
      trigger('startDate').then();
    }
  }

  function handleRecurringEndDateSelected(date: string) {
    setSelectedRecurringEndDate(date);

    // reset error message if exists
    if (isSubmitted) {
      trigger('startDate').then();
    }
  }

  function repeatsForDuration() {
    if (!selectedStartDate && !selectedRecurringEndDate) {
      return;
    }

    let duration = durationBetweenTwoDates(
      selectedStartDate,
      selectedRecurringEndDate,
      frequency
    );

    let durationType;
    if (frequency === ActivityRecurrenceFrequencyUnitEnum.Day) {
      durationType = duration === 1 ? 'day' : 'days';
    } else if (frequency === ActivityRecurrenceFrequencyUnitEnum.Week) {
      durationType = duration === 1 ? 'week' : 'weeks';
    } else if (frequency === ActivityRecurrenceFrequencyUnitEnum.Month) {
      duration = duration ? Math.floor(duration) : undefined;
      durationType = duration === 1 ? 'month' : 'months';
    }

    if (
      duration !== undefined &&
      duration > 0 &&
      durationType &&
      !errors.endDate
    ) {
      return `Repeats for ${duration} ${durationType}`;
    }
  }
  /* End Change Event Handlers */

  /* Submit form */
  function onSubmit(data: ActivityFormData) {
    if (isSubmitting) {
      return;
    }

    if (isActivityTypeGame && data.gameType) {
      data.activityType = data.gameType;
    }

    //handle external team ID
    if (selectedExternalTeam) {
      if (data.team === 'external') {
        data.team = selectedExternalTeam?.value;
      } else if (data.awayTeam === 'external') {
        data.awayTeam = selectedExternalTeam?.value;
      }
    }

    if (!subProgramId) {
      const teams: TeamWithProps[] = [];
      let team = teams.find((team) => team.id === Number(data.team));
      if (team) {
        subProgramId = team.programId.toString();
        data.subProgram = subProgramId;
      }
    } else {
      data.subProgram = subProgramId;
    }

    if (isEditForm) {
      // Edit case
      submitEditActivity(data);
    } else {
      // Create case
      createActivity(data, siteId, program?.id);
    }
  }

  async function createActivity(
    data: ActivityFormData,
    siteId: string | null,
    programId?: number
  ) {
    await createActivityHandler({
      data,
      siteId: Number(siteId),
      programId: Number(programId),
    })
      .then((data) => {
        let activity = data.activities ?? [data];
        setSubmitted({ activity });
      })
      .catch((e) => {
        const errorMessage = typeof e === 'string' ? e : e.message;
        setSubmitted({ errorMessage });
      });
  }

  // function getRecurringSetDirtyFieldValues(
  //   data: ActivityFormData
  // ): ActivityFormData {
  //   // Sub-location field is reset once location is changed,
  //   // which makes it seem 'dirty' even if same value is chosen
  //   const subLocationEdit =
  //     editActivity?.subLocationId?.toString() !== data.subLocation;
  //
  //   const dirtyFieldValues: ActivityFormData = {
  //     arrival: dirtyFields.arrival ? data.arrival : undefined,
  //     endTime: dirtyFields.endTime ? data.endTime : undefined,
  //     hasSubLocations: subLocationEdit,
  //     location: dirtyFields.location ? data.location : undefined,
  //     notes: dirtyFields.notes ? data.notes : undefined,
  //     startTime: dirtyFields.startTime ? data.startTime : undefined,
  //     subLocation:
  //       data.subLocation && subLocationEdit ? data.subLocation : undefined,
  //     title: dirtyFields.title ? data.title : undefined,
  //     //read only fields
  //     activityType: data.activityType,
  //     allDay: false,
  //     frequency: 'does_not_repeat',
  //     startDate: '',
  //     subProgram: '',
  //     team: '',
  //   };
  //
  //   return dirtyFieldValues;
  // }

  function submitEditActivity(data: ActivityFormData) {
    if (editActivity) {
      if (!formHasBeenChanged(data, editActivity)) {
        // const snackbar: SnackbarType = {
        //   isOpen: true,
        //   message: 'Please make at least one change before saving.',
        //   parentId: `#${formId}`,
        //   severity: 'warning',
        //   verticalAlignment: 'top',
        // };
        // toggleSnackbar(snackbar);
        return;
      }

      const isRecurringSet = editRecurringType === 'all';
      if (isRecurringSet) {
        // requestData = getRecurringSetDirtyFieldValues(data);
      }

      const existingTeamId = getHomeAwayTeamsId(editActivity?.teams).homeTeam;
      const teamHasBeenChanged = data.team !== existingTeamId?.toString();
      const existingAwayTeamId = getHomeAwayTeamsId(
        editActivity?.teams
      ).awayTeam;
      const awayTeamHasBeenChanged =
        data.awayTeam !== existingAwayTeamId?.toString();

      if (teamHasBeenChanged || awayTeamHasBeenChanged) {
        // const teamUpdateId = parseInt(data.team, 10);
        // const awayTeamUpdateId = data.awayTeam
        //   ? parseInt(data.awayTeam, 10)
        //   : undefined;
        // setTeamsUpdateId({
        //   teamId: teamHasBeenChanged ? teamUpdateId : undefined,
        //   awayTeamId: awayTeamHasBeenChanged ? awayTeamUpdateId : undefined,
        // });
      }

      // openActivityFormSubmissionModal({
      //   dataToSubmit: requestData,
      //   notify: notificationsEnabled,
      // });
    }
  }

  const onTeamSelect = (value?: string) => {
    setIsTeamSelectOpen(!isTeamSelectOpen);

    let external: ExternalTeamType =
      externalTeamNameLabel === 'home' ? undefined : externalTeamNameLabel;

    if (value === 'external') {
      setSelectedTeam({ value: value, label: 'External team' });
      external = 'home';
    } else if (value) {
      const teamName = teams.find(({ id }) => id === Number(value))?.name || '';
      setSelectedTeam({ value: value, label: teamName });
      if (!external) {
        //reset external team value
        resetField('externalTeam', { defaultValue: '', keepError: true });
        setSelectedExternalTeam(undefined);
      }
    } else {
      setSelectedTeam(undefined);
    }

    setExternalTeamNameLabel(external);
  };

  const onAwayTeamSelect = (value?: string) => {
    setIsAwayTeamSelectOpen(!isAwayTeamSelectOpen);
    let external: ExternalTeamType =
      externalTeamNameLabel === 'away' ? undefined : externalTeamNameLabel;

    if (value === 'external') {
      setSelectedAwayTeam({ value: value, label: 'External team' });
      external = 'away';
    } else if (value) {
      const teamName = teams.find(({ id }) => id === Number(value))?.name || '';
      setSelectedAwayTeam({ value: value, label: teamName });

      if (!external) {
        //reset external team value
        resetField('externalTeam', { defaultValue: '', keepError: true });
        setSelectedExternalTeam(undefined);
      }
    } else {
      setSelectedAwayTeam(undefined);
    }

    setExternalTeamNameLabel(external);
  };

  const onExternalTeamSelect = (type: ExternalTeamType, value?: string) => {
    if (type === 'home') {
      setIsExternalHomeTeamNameSelectOpen(!isExternalHomeTeamNameSelectOpen);
    } else if (type === 'away') {
      setIsExternalAwayTeamNameSelectOpen(!isExternalAwayTeamNameSelectOpen);
    }

    if (value) {
      let teamName = 'placeholder';
      setSelectedExternalTeam({ value: value, label: teamName });
    } else {
      setSelectedExternalTeam(undefined);
    }
  };

  const handleDiscardChanges = () => {
    const { sld, subdomain, tld } = getLAHostnameParts();
    window.location.href = `https://${subdomain}.${sld}.${tld}/${originRoute}`;
  };

  return (
    <>
      {!submitted ? (
        <S.StyledForm
          id={formId}
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(onSubmit)();
          }}
          noValidate
        >
          {editRecurringType !== undefined ? (
            <div id="snackbar-recurring-message"></div>
          ) : null}
          <S.Flex>
            <Controller
              control={control}
              name="activityType"
              render={({ field }) => {
                return (
                  <Select
                    id="activityType"
                    label="Activity type"
                    placeholder="Select an activity type"
                    options={activityTypeOptions}
                    {...field}
                    readOnly={isEditForm}
                    required
                    errorMessage={errors.activityType?.message}
                    hasError={!!errors.activityType?.message}
                  />
                );
              }}
            />
            {isActivityTypeGame ? (
              <Controller
                control={control}
                name="gameType"
                render={({ field }) => {
                  return (
                    <Select
                      id="gameType"
                      label="Game type"
                      placeholder="Select a game type"
                      options={gameTypeOptions}
                      {...field}
                      required
                      readOnly={isEditForm}
                      // errorMessage={errors.gameType?.message}
                      hasError={!!errors.gameType?.message}
                    />
                  );
                }}
              />
            ) : null}
          </S.Flex>

          {/*<Controller*/}
          {/*  name="subProgram"*/}
          {/*  control={control}*/}
          {/*  render={({ field }) => {*/}
          {/*    return (*/}
          {/*      <Select*/}
          {/*        id="sub-program-menu"*/}
          {/*        label="Sub-program"*/}
          {/*        options={subProgramOptions}*/}
          {/*        placeholder={`Select a sub-program`}*/}
          {/*        {...field}*/}
          {/*        onChange={(newSubProgramId) => {*/}
          {/*          if (newSubProgramId !== selectedSubProgramId) {*/}
          {/*            field.onChange(newSubProgramId);*/}
          {/*            handleSubProgramChange(newSubProgramId);*/}
          {/*          }*/}
          {/*        }}*/}
          {/*        required={isActivityTypeGame}*/}
          {/*        readOnly={editRecurringType === 'all'}*/}
          {/*        hasError={!!errors.subProgram?.message}*/}
          {/*        errorMessage={errors.subProgram?.message}*/}
          {/*      />*/}
          {/*    );*/}
          {/*  }}*/}
          {/*/>*/}
          <Controller
            control={control}
            name="team"
            render={({ field }) => {
              return (
                <SelectPopover
                  id="team"
                  label={isActivityTypeGame ? 'Home team' : 'Team'}
                  placeholder={
                    isActivityTypeGame ? 'Select home team' : 'Select a team'
                  }
                  size="large"
                  onOpenChange={() => onTeamSelect(field.value)}
                  open={isTeamSelectOpen}
                  content={
                    <TeamPrimaryStaffSelect
                      onClose={onTeamSelect}
                      showExternalOption={showHomeExternalTeamOption}
                      teams={homeTeamOptions}
                      {...field}
                    />
                  }
                  value={selectedTeam?.label}
                  readOnly
                  required
                  hasError={!!errors.team?.message}
                  errorMessage={errors.team?.message}
                  // disabled={isActivityTypeGame && selectedSubProgramId === ''}
                />
              );
            }}
          />
          {externalTeamNameLabel === 'home' ? (
            <Controller
              control={control}
              name="externalTeam"
              render={({ field }) => {
                return (
                  <SelectPopover
                    id="externalTeam"
                    label="External team name"
                    placeholder="Select external team"
                    size="large"
                    onOpenChange={() =>
                      onExternalTeamSelect('home', field.value)
                    }
                    open={isExternalHomeTeamNameSelectOpen}
                    content={
                      <ExternalTeamSelect
                        onClose={(value?: string) =>
                          onExternalTeamSelect('home', value)
                        }
                        teams={externalTeamOptions}
                        selectedSubProgramId={selectedSubProgramId}
                        {...field}
                      />
                    }
                    value={selectedExternalTeam?.label}
                    required
                    readOnly={editRecurringType === 'all'}
                    hasError={!!errors.externalTeam?.message}
                    errorMessage={errors.externalTeam?.message}
                  />
                );
              }}
            />
          ) : null}

          {isActivityTypeGame ? (
            <Controller
              control={control}
              name="awayTeam"
              render={({ field }) => {
                return (
                  <SelectPopover
                    id="awayTeam"
                    label="Away team"
                    placeholder="Select away team"
                    size="large"
                    onOpenChange={() => {
                      const awayTeam = field.value ?? undefined;
                      onAwayTeamSelect(awayTeam);
                    }}
                    open={isAwayTeamSelectOpen}
                    content={
                      <TeamPrimaryStaffSelect
                        onClose={onAwayTeamSelect}
                        showExternalOption={showAwayExternalTeamOption}
                        teams={awayTeamOptions}
                        {...field}
                      />
                    }
                    value={selectedAwayTeam?.label}
                    readOnly={editRecurringType === 'all'}
                    required
                    hasError={!!errors.awayTeam?.message}
                    errorMessage={errors.awayTeam?.message}
                    // disabled={isActivityTypeGame && selectedSubProgramId === ''}
                  />
                );
              }}
            />
          ) : null}

          {externalTeamNameLabel === 'away' ? (
            <Controller
              control={control}
              name="externalTeam"
              render={({ field }) => {
                return (
                  <SelectPopover
                    id="externalTeam"
                    label="External team name"
                    placeholder="Select external team"
                    size="large"
                    onOpenChange={() =>
                      onExternalTeamSelect('away', field.value)
                    }
                    open={isExternalAwayTeamNameSelectOpen}
                    content={
                      <ExternalTeamSelect
                        onClose={(value?: string) =>
                          onExternalTeamSelect('away', value)
                        }
                        selectedSubProgramId={selectedSubProgramId}
                        teams={externalTeamOptions}
                        {...field}
                      />
                    }
                    required
                    readOnly={editRecurringType === 'all'}
                    value={selectedExternalTeam?.label}
                    hasError={!!errors.externalTeam?.message}
                    errorMessage={errors.externalTeam?.message}
                  />
                );
              }}
            />
          ) : null}

          <Controller
            name="location"
            control={control}
            render={({ field }) => (
              <Select
                id="location-menu"
                label="Location"
                options={locationsOptions}
                placeholder="Select a location"
                {...field}
                onChange={(newLocationId) => {
                  if (newLocationId === selectedLocationId) {
                    return;
                  }
                  field.onChange(newLocationId);
                  handleLocationChange(newLocationId);
                }}
                hasError={!!errors.location?.message}
                errorMessage={errors.location?.message}
              />
            )}
          />
          <input {...register('hasSubLocations')} type="hidden" value="false" />
          {subLocationOptions.length > 0 ? (
            <Controller
              name="subLocation"
              control={control}
              render={({ field }) => (
                <Select
                  id="sub-location-menu"
                  label="Sub-location"
                  options={subLocationOptions}
                  placeholder="Select a sub-location"
                  {...field}
                  onChange={(newSubLocationId) => {
                    if (newSubLocationId === field.value) {
                      return;
                    }
                    field.onChange(newSubLocationId);
                  }}
                  hasError={!!errors.subLocation?.message}
                  errorMessage={errors.subLocation?.message}
                />
              )}
            />
          ) : null}
          <S.BorderedFormSection>
            <S.FlexEnd>
              {editRecurringType !== 'all' ? (
                <Controller
                  name="startDate"
                  control={control}
                  render={({ field }) => (
                    <div>
                      <Typography variant="ui" size="medium" weight="bold">
                        <S.Label error={!!errors.startDate?.message}>
                          Start Date
                        </S.Label>
                      </Typography>
                      <DatePicker
                        date={selectedStartDate}
                        defaultInputProps={{
                          name: field.name,
                          id: 'start-date',
                          label: '',
                          placeholder: 'Select a start date',
                        }}
                        error={!!errors.startDate?.message}
                        fullWidth
                        onDateSelect={(dt) => {
                          if (dt) {
                            const date = formatDate(dt);
                            field.onChange(date);
                            handleStartDateSelected(date);
                          }
                        }}
                      />
                      <ActivityFormErrorMessage
                        errorMessage={errors.startDate?.message}
                      />
                    </div>
                  )}
                />
              ) : null}
              {(isEditForm && !allDayActivity) || isActivityTypeGame ? null : (
                <Controller
                  name="allDay"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      ariaLabel="All Day"
                      id="all-day-activity"
                      checked={allDayActivity}
                      label="All Day"
                      onCheckedChange={(allDay: boolean) => {
                        field.onChange(allDay);
                        handleAllDayChange(allDay);
                      }}
                      disabled={isEditForm}
                      size="large"
                    />
                  )}
                />
              )}
            </S.FlexEnd>

            {!allDayActivity ? null : (
              <Controller
                name="endDate"
                control={control}
                render={({ field }) => (
                  <div>
                    <Typography variant="ui" size="medium" weight="bold">
                      <S.Label error={!!errors.endDate?.message}>
                        End Date
                      </S.Label>
                    </Typography>
                    <DatePicker
                      date={selectedEndDate}
                      defaultInputProps={{
                        name: field.name,
                        id: 'end-date',
                        label: '',
                        placeholder: 'Select a end date',
                      }}
                      error={!!errors.endDate?.message}
                      fullWidth
                      onDateSelect={(dt) => {
                        if (dt) {
                          const date = formatDate(dt);
                          field.onChange(date);
                          handleAllDayEndDateSelected(date);
                        }
                      }}
                    />
                    <ActivityFormErrorMessage
                      errorMessage={errors.endDate?.message}
                    />
                  </div>
                )}
              />
            )}

            {!isEditForm ? (
              <Controller
                name="frequency"
                control={control}
                render={({ field }) => (
                  <Select
                    id="frequency"
                    disabled={allDayActivity}
                    label="Frequency"
                    placeholder="Select a frequency"
                    options={frequencyOptions}
                    {...field}
                    onChange={(frequency: string) => {
                      field.onChange(frequency);
                    }}
                    required
                    readOnly={isEditForm}
                    hasError={!!errors.frequency?.message}
                    // errorMessage={errors.frequency?.message}
                  />
                )}
              />
            ) : null}

            {!allDayActivity && frequency && frequency !== 'does_not_repeat' ? (
              <S.RecurringDate>
                <Typography variant="ui" size="large" weight="regular">
                  Repeats every {frequency} until:
                </Typography>
                <div>
                  <Controller
                    name="endDate"
                    control={control}
                    render={({ field }) => (
                      <div>
                        <DatePicker
                          date={selectedRecurringEndDate}
                          defaultInputProps={{
                            name: field.name,
                            id: 'end-date',
                            label: '',
                            placeholder: 'Select an end date',
                          }}
                          error={!!errors.endDate?.message}
                          fullWidth
                          onDateSelect={(dt) => {
                            if (dt) {
                              const date = formatDate(dt);
                              field.onChange(date);
                              handleRecurringEndDateSelected(date);
                            }
                          }}
                        />
                        <ActivityFormErrorMessage
                          errorMessage={errors.endDate?.message}
                        />
                      </div>
                    )}
                  />
                  {selectedStartDate && selectedRecurringEndDate ? (
                    <Typography
                      variant="ui"
                      size="medium"
                      weight="regular"
                      className="repeatsFor"
                    >
                      {repeatsForDuration()}
                    </Typography>
                  ) : null}
                </div>
              </S.RecurringDate>
            ) : null}

            <S.Flex>
              <Controller
                name="arrival"
                control={control}
                render={({ field }) => (
                  <div>
                    <Typography variant="ui" size="medium" weight="bold">
                      <S.Label
                        error={!!errors.arrival?.message}
                        disabled={allDayActivity}
                      >
                        Arrival time
                      </S.Label>
                    </Typography>
                    <TextField
                      disabled={allDayActivity}
                      error={!!errors.arrival?.message}
                      helperText={errors.arrival?.message}
                      fullWidth
                      id="arrival-time"
                      data-testid="arrival-time"
                      label=""
                      type="time"
                      {...field}
                      onChange={(
                        evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                      ) => {
                        field.onChange(evt.target.value);
                      }}
                      ref={null}
                    />
                  </div>
                )}
              />
              <Controller
                name="startTime"
                control={control}
                render={({ field }) => (
                  <div>
                    <Typography variant="ui" size="medium" weight="bold">
                      <S.Label
                        error={!!errors.startTime?.message}
                        disabled={allDayActivity}
                      >
                        Start time
                      </S.Label>
                    </Typography>
                    <TextField
                      disabled={allDayActivity}
                      error={!!errors.startTime?.message}
                      fullWidth
                      helperText={errors.startTime?.message}
                      id="start-time"
                      data-testid="start-time"
                      label=""
                      type="time"
                      {...field}
                      onChange={(
                        evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                      ) => {
                        field.onChange(evt.target.value);

                        if (isSubmitted) {
                          trigger('endTime').then();
                        }
                      }}
                      ref={null}
                    />
                  </div>
                )}
              />

              <Controller
                name="endTime"
                control={control}
                render={({ field }) => (
                  <div>
                    <Typography variant="ui" size="medium" weight="bold">
                      <S.Label
                        error={!!errors.endTime?.message}
                        disabled={allDayActivity}
                      >
                        End time
                      </S.Label>
                    </Typography>
                    <TextField
                      disabled={allDayActivity}
                      error={!!errors.endTime?.message}
                      fullWidth
                      helperText={errors.endTime?.message}
                      id="end-time"
                      data-testid="end-time"
                      label=""
                      type="time"
                      {...field}
                      onChange={(
                        evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                      ) => {
                        field.onChange(evt.target.value);

                        if (isSubmitted) {
                          trigger('startTime').then();
                        }
                      }}
                      ref={null}
                    />
                  </div>
                )}
              />
            </S.Flex>
          </S.BorderedFormSection>
          <Controller
            name="notes"
            control={control}
            render={({ field }) => (
              <div>
                <Typography variant="ui" size="medium" weight="bold">
                  <S.Label>Notes</S.Label>
                </Typography>
                <TextArea
                  id="notes"
                  label=""
                  maxLength={200}
                  placeholder="Add notes"
                  rows={3}
                  {...field}
                  ref={null}
                />
              </div>
            )}
          />
          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <div>
                <Typography variant="ui" size="medium" weight="bold">
                  <S.Label>Custom title</S.Label>
                </Typography>
                <TextInput
                  id="activity-title"
                  type="text"
                  {...field}
                  ref={null}
                />
              </div>
            )}
          />
          {isEditForm &&
          editActivity?.state === ActivityState.Scheduled &&
          editRecurringType !== 'all' ? (
            <S.CancelSection>
              <S.CancelText>
                Would you like to cancel this activity?
              </S.CancelText>
              <Button id="cancel-button" onClick={() => alert('🤓')}>
                Cancel Activity
              </Button>
            </S.CancelSection>
          ) : null}
          <S.FormButtons>
            <Button onClick={handleDiscardChanges} variant="text" size="large">
              Discard Changes
            </Button>

            <Button form={formId} type="submit" variant="primary" size="large">
              {isEditForm ? 'Save Changes' : 'Create Activity'}
            </Button>
          </S.FormButtons>
        </S.StyledForm>
      ) : (
        <ActivityFormConfirmation
          confirmation={submitted}
          teams={teams}
          originRoute={originRoute}
        />
      )}
    </>
  );
}
/* */

function ActivityFormErrorMessage({ errorMessage }: { errorMessage?: string }) {
  return (
    <>
      {errorMessage ? (
        <S.ActivityFormErrorMessage>
          <InputErrorMessage>{errorMessage}</InputErrorMessage>
        </S.ActivityFormErrorMessage>
      ) : null}
    </>
  );
}

function formatDate(date: DateTime) {
  const dateAsISO = convertDateTimeToISO(date);
  if (dateAsISO) {
    return formatISODateTime(dateAsISO, 'y-MM-dd');
  }

  return '';
}

export function isGameActivity(
  activityType: ActivityType | AllDayActivityType
) {
  return (
    activityType === ActivityType.GameConsolation ||
    activityType === ActivityType.GameFriendly ||
    activityType === ActivityType.GameLeague ||
    activityType === ActivityType.GamePlayoff ||
    activityType === ActivityType.GameScrimmage ||
    activityType === ActivityType.GameSeason ||
    activityType === ActivityType.GameTournament
  );
}
