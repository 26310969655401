import { BillingSummaryData, BillingSummaryItem } from '../Checkout.types';
import * as S from './CheckoutBillingSummary.styles';

export type CheckoutBillingSummaryProps = {
  billingSummaryData: BillingSummaryData;
  summaryType: 'checkout' | 'confirmation';
};

export const DUE_TODAY = 'Due today:';
export const PAID_TODAY = 'Paid today:';

/* CheckoutBillingSummary */
export default function CheckoutBillingSummary({
  billingSummaryData,
  summaryType,
}: CheckoutBillingSummaryProps) {
  const { dueToday, totalAmount, transactionFees, summary } =
    billingSummaryData;

  const hasPaymentPlan = summary.find(
    (summaryItem) => summaryItem.paymentTerm !== 'FULL'
  );
  return (
    <S.CheckoutBillingSummary $summaryType={summaryType}>
      <CheckoutBillingSummaryHeader
        dueToday={dueToday}
        summaryType={summaryType}
        totalAmount={totalAmount}
        hasPaymentPlan={!!hasPaymentPlan}
      />
      <CheckoutBillingSummaryDetail
        summaryType={summaryType}
        summaryItems={summary}
      />
      <TransactionFee transanctionFee={`$${transactionFees}`} />
      <CheckoutBillingSummaryMobileFooter
        dueToday={dueToday}
        summaryType={summaryType}
        totalAmount={totalAmount}
        hasPaymentPlan={!!hasPaymentPlan}
      />
    </S.CheckoutBillingSummary>
  );
}
/* */

/*CheckoutBillingSummaryHeader */
function CheckoutBillingSummaryHeader({
  dueToday,
  summaryType,
  totalAmount,
  hasPaymentPlan,
}: {
  dueToday: string;
  summaryType: 'checkout' | 'confirmation';
  totalAmount: string;
  hasPaymentPlan: boolean;
}) {
  const rowLabel =
    summaryType === 'checkout'
      ? hasPaymentPlan
        ? DUE_TODAY
        : 'Total due:'
      : PAID_TODAY;

  return (
    <S.CheckoutBillingSummaryHeader>
      <S.DueTodayRow $summaryType={summaryType}>
        <span>{rowLabel}</span>
        <span>{`$${dueToday}`}</span>
      </S.DueTodayRow>
      {hasPaymentPlan ? (
        <S.TotalPriceRow>
          <span>Total price:</span>
          <S.TotalPrice>{`$${totalAmount}`}</S.TotalPrice>
        </S.TotalPriceRow>
      ) : null}
    </S.CheckoutBillingSummaryHeader>
  );
}
/* */

/*CheckoutBillingSummaryMobileFooter */
function CheckoutBillingSummaryMobileFooter({
  dueToday,
  summaryType,
  totalAmount,
  hasPaymentPlan,
}: {
  dueToday: string;
  summaryType: 'checkout' | 'confirmation';
  totalAmount: string;
  hasPaymentPlan: boolean;
}) {
  const rowLabel =
    summaryType === 'checkout'
      ? hasPaymentPlan
        ? DUE_TODAY
        : 'Total due:'
      : PAID_TODAY;

  return (
    <S.CheckoutBillingSummaryMobileFooter>
      {hasPaymentPlan ? (
        <S.TotalPriceRow>
          <span>Total price:</span>
          <S.TotalPrice>{`$${totalAmount}`}</S.TotalPrice>
        </S.TotalPriceRow>
      ) : null}
      <S.DueTodayRow $summaryType={summaryType}>
        <span>{rowLabel}</span>
        <span>{`$${dueToday}`}</span>
      </S.DueTodayRow>
    </S.CheckoutBillingSummaryMobileFooter>
  );
}
/* */

/*CheckoutBillingSummaryDetail */
function CheckoutBillingSummaryDetail({
  summaryItems,
  summaryType,
}: {
  summaryItems: BillingSummaryItem[];
  summaryType: 'checkout' | 'confirmation';
}) {
  function renderSummaryItems() {
    return summaryItems.map(
      (summaryItemData: BillingSummaryItem, idx: number) => (
        <SummaryItem
          {...summaryItemData}
          key={`${summaryItemData.cartItemName}-${idx}`}
        />
      )
    );
  }
  return <S.SummaryItems>{renderSummaryItems()}</S.SummaryItems>;
}
/* */

function SummaryItem({
  cartItemName,
  description,
  dueToday,
  paymentTerm,
  paymentTermLabel,
  totalAmount,
}: BillingSummaryItem) {
  return (
    <S.SummaryItem>
      <S.CheckoutBillingSummaryPrimaryRow>
        <span>{cartItemName}:</span>
        <span>
          {paymentTerm === 'PAYMENT_PLAN' ? <>{`$${dueToday}`}/</> : null}
          <S.TotalCost>{`$${totalAmount}`}</S.TotalCost>
        </span>
      </S.CheckoutBillingSummaryPrimaryRow>
      <S.CheckoutBillingSummarySecondaryRow>
        <span>{description}</span>
        <span>{paymentTermLabel}</span>
      </S.CheckoutBillingSummarySecondaryRow>
    </S.SummaryItem>
  );
}

function TransactionFee({ transanctionFee }: { transanctionFee: string }) {
  return (
    <S.TransactionFee>
      <span> Transaction fees:</span>
      <span>{transanctionFee}</span>
    </S.TransactionFee>
  );
}
