import { useState } from 'react';
import 'react-router-dom';
import { Button, DeleteIcon, Typography } from '@la/ds-ui-components';
import useMediaQuery from 'lib/hooks/useMediaQuery';
import { breakpointQueries } from 'lib/media-queries/breakpoints';
import DeleteRegistrantModal from './DeleteRegistrantModal';
import { EditRegistrantProfileModal } from './EditRegistrantProfileModal';
import * as S from './RosterManagement.styles';

export type Registrant = {
  avatarUrl: string;
  isPrimaryStaff?: boolean;
  name: string;
  role?: string;
  status?: 'complete' | 'incomplete';
  userId: number;
};

export type TableType = 'players' | 'staff';

export type RosterTableData = {
  registrants: Registrant[];
  tableType: TableType;
};

export type TargetRegistrant = {
  memberName: string;
  tableType: TableType;
  userId: number;
};

/* Roster Table */
export default function RosterTable({
  registrants,
  tableType,
}: RosterTableData) {
  const [targetRegistrantData, setTargetRegistrantData] =
    useState<TargetRegistrant | null>(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] =
    useState<boolean>(false);
  const [isEditProfileModalVisible, setIsEditProfileModalVisible] =
    useState<boolean>(false);

  const { phoneOnly, tabletPortraitUp, tabletLandscapeUp } = breakpointQueries;
  const isPhone = useMediaQuery(phoneOnly);
  const isTabletPortrait = useMediaQuery(tabletPortraitUp);
  const isTabletLandscape = useMediaQuery(tabletLandscapeUp);

  let registrantNameWeight: 'medium' | 'xl' = 'xl',
    roleLabelSize: 'small' | 'medium' = 'medium',
    statusLabelSize: 'medium' | 'xs' = 'medium',
    statusLabelWeight: 'regular' | 'bold' = 'regular';

  if (isPhone) {
    registrantNameWeight = 'medium';
    roleLabelSize = 'small';
    statusLabelSize = 'xs';
    statusLabelWeight = 'bold';
  } else if (isTabletPortrait) {
    statusLabelSize = 'medium';
    statusLabelWeight = 'regular';
  } else if (isTabletLandscape) {
    statusLabelSize = 'medium';
    statusLabelWeight = 'regular';
  }

  const editButtonLabel = isTabletPortrait ? 'Edit profile' : 'Edit';

  function handleEditButtonClicked(
    targetRegistrantData: TargetRegistrant
  ): void {
    setIsEditProfileModalVisible(true);
    setTargetRegistrantData(targetRegistrantData);
  }

  function handleDeleteButtonClicked(
    targetRegistrantData: TargetRegistrant
  ): void {
    setIsDeleteModalVisible(true);
    setTargetRegistrantData(targetRegistrantData);
  }

  function handleModalClosed(): void {
    setTargetRegistrantData(null);
    setIsDeleteModalVisible(false);
    setIsEditProfileModalVisible(false);
    setTimeout(() => {
      document.body.style.removeProperty('pointer-events');
    }, 0);
  }

  function generateRosterTableRows() {
    return registrants.map(
      (
        { avatarUrl, isPrimaryStaff, name, userId, role, status },
        idx: number
      ) => {
        return (
          <RosterTableRow
            avatarUrl={avatarUrl}
            editButtonLabel={editButtonLabel}
            isPrimaryStaff={isPrimaryStaff}
            name={name}
            userId={userId}
            registrantNameWeight={registrantNameWeight}
            role={role}
            roleLabelSize={roleLabelSize}
            status={status}
            statusLabelSize={statusLabelSize}
            statusLabelWeight={statusLabelWeight}
            tableType={tableType}
            key={`${tableType}-${idx}`}
            editRegistrantProfile={handleEditButtonClicked}
            deleteRegistrantProfile={handleDeleteButtonClicked}
          />
        );
      }
    );
  }

  return (
    <>
      <S.RosterTable role="table">
        <S.RosterTableHead>
          <S.RosterTableColumn_Avatar>&nbsp;</S.RosterTableColumn_Avatar>
          <S.RosterTableColumn_Name>
            <Typography variant="ui" size="medium" weight="bold">
              Name
            </Typography>
          </S.RosterTableColumn_Name>
          <div>
            <Typography variant="ui" size="medium" weight="bold">
              {tableType === 'players' ? 'Status' : null}
            </Typography>
          </div>
          <S.RosterTableColumn_EditDelete>
            &nbsp;
          </S.RosterTableColumn_EditDelete>
        </S.RosterTableHead>
        <S.RosterTableBody>{generateRosterTableRows()}</S.RosterTableBody>
      </S.RosterTable>
      {targetRegistrantData && isEditProfileModalVisible ? (
        <EditRegistrantProfileModal
          {...targetRegistrantData}
          closeModal={handleModalClosed}
        />
      ) : null}
      {targetRegistrantData && isDeleteModalVisible ? (
        <DeleteRegistrantModal
          {...targetRegistrantData}
          closeModal={handleModalClosed}
        />
      ) : null}
    </>
  );
}
/* */

/* Roster Table Row */
export function RosterTableRow({
  avatarUrl,
  deleteRegistrantProfile,
  editButtonLabel,
  editRegistrantProfile,
  isPrimaryStaff,
  name,
  registrantNameWeight,
  role,
  roleLabelSize,
  status,
  statusLabelSize,
  statusLabelWeight,
  tableType,
  userId,
}: {
  avatarUrl: string | null;
  deleteRegistrantProfile: (targetRegistrantData: TargetRegistrant) => void;
  editRegistrantProfile: (targetRegistrantData: TargetRegistrant) => void;
  editButtonLabel: 'Edit profile' | 'Edit';
  isPrimaryStaff?: boolean;
  name: string;
  registrantNameWeight: 'medium' | 'xl';
  role?: string;
  roleLabelSize: 'small' | 'medium';
  status?: 'complete' | 'incomplete';
  statusLabelSize: 'xs' | 'medium';
  statusLabelWeight: 'regular' | 'bold';
  tableType: TableType;
  userId: number;
}) {
  function onDeleteProfileClicked(): void {
    deleteRegistrantProfile({
      memberName: name,
      tableType: tableType,
      userId: userId,
    });
  }
  function onEditProfileClicked(): void {
    editRegistrantProfile({
      memberName: name,
      tableType: tableType,
      userId: userId,
    });
  }

  return (
    <S.RosterTableRow>
      <S.RosterTableColumn_Avatar>
        <RosterAvatar avatarUrl={avatarUrl} name={name} />
      </S.RosterTableColumn_Avatar>
      <S.RosterTableColumn_Name>
        <Typography variant="ui" size={registrantNameWeight} weight="bold">
          {name}
        </Typography>
        {role ? (
          <Typography variant="ui" size={roleLabelSize} weight="regular">
            {role}
          </Typography>
        ) : null}
      </S.RosterTableColumn_Name>
      <S.RosterStatusBadge $status={status ?? 'complete'}>
        {tableType === 'players' ? (
          <Typography
            variant="ui"
            size={statusLabelSize}
            weight={statusLabelWeight}
          >
            {status}
          </Typography>
        ) : null}
      </S.RosterStatusBadge>
      <S.RosterTableColumn_EditDelete>
        {tableType === 'players' ? (
          <Button
            variant="outline"
            size="medium"
            onClick={onEditProfileClicked}
          >
            {editButtonLabel}
          </Button>
        ) : null}
        <S.DeleteButton
          aria-label="delete registrant"
          onClick={onDeleteProfileClicked}
          data-testid={`delete-button-${userId}`}
          disabled={isPrimaryStaff}
        >
          <DeleteIcon variant="bold" size="medium" />
        </S.DeleteButton>
      </S.RosterTableColumn_EditDelete>
    </S.RosterTableRow>
  );
}
/*  */

/* Roster Avatar */
export function RosterAvatar({
  avatarUrl,
  name,
}: {
  avatarUrl: string | null;
  name: string;
}) {
  const [shouldUseImage, setShouldUseImage] = useState<boolean>(!!avatarUrl);
  function extractInitials(name: string) {
    const arr = name.split(' ');
    const first = arr[0].slice(0, 1);
    const last = arr.length > 1 ? arr[arr.length - 1].slice(0, 1) : '';
    return first + last;
  }
  return (
    <S.RosterAvatar>
      {avatarUrl && shouldUseImage ? (
        <img
          src={avatarUrl}
          alt={extractInitials(name)}
          height="56"
          onError={() => setShouldUseImage(false)}
        />
      ) : (
        <Typography variant="headline" size="small">
          {extractInitials(name)}
        </Typography>
      )}
    </S.RosterAvatar>
  );
}
/*  */
